@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.NotFound {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	@include mediaQuery(sml) {
		right: $margin-horizontal-mobile;
	}

	@include mediaQuery(lrg) {
		left: 75%;
		transform: translate(-50%, -50%);
	}

	.header {
		font-family: $font-family-martin;
		font-size: 100px;
		line-height: 90px;
		color: $text-color-dark;
		margin-bottom: 10px;
		transition: $color-transition;

		@include mediaQuery(lrg) {
			font-size: 170px;
			line-height: 120px;
			margin-bottom: 40px;
		}
	}

	.sub-header {
		font-family: $font-family-william;
		font-size: 18px;
		color: $text-color-dark;
		transition: $color-transition;
		white-space: nowrap;
		text-transform: uppercase;

		@include mediaQuery(lrg) {
			font-size: 26px;
			line-height: 30px;
		}
	}

	.content {
		font-family: $font-family-william;
		font-size: 12px;
		color: rgba(0, 0, 0, 0.5);
		transition: $color-transition;
		max-width: 230px;
		margin-bottom: 30px;

		@include mediaQuery(lrg) {
			font-size: 14px;
			line-height: 20px;
		}
	}

	.cta {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.icon {
			width: 16px;
			height: 21px;
			background-color: $text-color-dark;
			-webkit-mask: url(../../../images/icons/previous-arrow.svg) no-repeat center;
			mask: url(../../../images/icons/previous-arrow.svg) no-repeat center;
			transition: $background-color-transition;
			margin-right: 10px;
		}

		.label {
			font-family: $font-family-william;
			font-size: 20px;
			line-height: 22px;
			color: $text-color-dark;
			transition: $color-transition;
		}
	}
}

@include hover {
	.cta:hover {
		.icon {
			background-color: $text-color;
		}
		.label {
			color: $text-color;
		}
	}
}

.OnBoardingSection {
	overflow: hidden;

	.content-wrapper {
		width: 100vw;

		&.android {
			width: calc(100vw - 8px);
		}
	}
}

@import '../../../../../scss/constants.scss';
@import '../../../../../scss/mixins.scss';

.AboutQuote {
  display: block;
  margin: 25px 0 35px;

  .quote-icon {
    font-family: $font-family-martin;
    color: $color-gold;
    white-space: nowrap;
    margin-bottom: 20px;
    overflow: hidden;

    > span {
      display: inline-block;
      width: 56px;
      height: 0;
      border-top: 2px solid $color-gold;
      padding-bottom: 10px;
    }

    > img {
      display: inline-block;
      height: 50px;
      margin: 0 20px;
    }
  }

  .quote-text {
    font-family: $font-family-martin;
    font-size: 80px;
    line-height: 56px;
    text-transform: uppercase;
    max-width: 840px;
    @include mediaQuery(mobile) {
      font-size: 60px;
      line-height: 42px;
    }
    > span {
      display: inline;
      position: relative;
      overflow: hidden;
      > span {
        display: inline-block;
      }
    }
  }

  .quote-attrib {
    font-family: $font-family-william;
    font-size: 14px;
    line-height: 20px;
    color: $color-gold;
    margin: 20px 0;
    letter-spacing: 0.03em;
    &::before {
      content: '—';
    }
  }

  
}
@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.CurrentLocation {
	@include center-x;
	top: 144px;
	text-align: center;
	z-index: $layer-ui-1;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

	.location {
		font-family: $font-family-martin;
		font-size: 50px;
		line-height: 38px;
		color: $text-green;
		text-transform: uppercase;
		text-align: center;
		white-space: nowrap;
	}

	.bei {
		font-family: $font-family-william;
		font-size: 20px;
		color: $text-color;
		line-height: 22px;
		text-transform: uppercase;
		text-align: center;
	}

	.ft {
		font-family: $font-family-william;
		font-size: 12px;
		color: $text-color;
		text-transform: uppercase;
	}

	.divider {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		height: 20px;
		padding: 8px 0 4px 0;

		.line {
			width: 56px;
			height: 2px;
			background-color: $text-color;
		}

		.left {
			@extend .line;
			margin-right: 20px;
		}

		.middle {
			width: 5.66px;
			height: 5.66px;
			transform: rotate(-45deg);
			background-color: $text-color;
		}

		.right {
			@extend .line;
			margin-left: 20px;
		}
	}
}

@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.FormSubmitButton {
  cursor: pointer;
  overflow: hidden;
  height: 30px;
  flex-shrink: 0;

  &.disabled {
    pointer-events: none;

    .form-submit-content {
      .form-submit-button {
        opacity: 0.5;
      }
    }
  }

  &.show-message {
    pointer-events: none;

    .form-submit-content {
      transform: translateY(10px);

      @include mediaQuery(lrg) {
        transform: translateY(7px);
      }
    }
  }

  .form-submit-content {
    height: 64px;
    transition: transform 0.6s $ease-in-out-quint;
    transform: translateY(-37px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form-submit-message {
      font-family: $font-family-william;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.02em;
      color: $text-color;
      text-transform: uppercase;
      // height: 30px;
      // margin-bottom: 20px;

      @include mediaQuery(lrg) {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .form-submit-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .form-submit-label {
        font-family: $font-family-william;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.02em;
        color: $color-gold;
        transition: $color-transition;
        text-transform: uppercase;
        margin-right: 4px;

        @include mediaQuery(lrg) {
          font-size: 18px;
          line-height: 18px;
        }
      }

      .form-submit-arrows {
        width: 16px;
        height: 21px;
        background-color: $color-gold;
        transition: $background-color-transition;
        -webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center;
        mask: url(../../../images/icons/next-arrow.svg) no-repeat center;

        @include mediaQuery(sml) {
          transform: scale(0.8125);
        }
      }
    }
  }

  &.dark-mode {
    .form-submit-content {
      .form-submit-message {
        color: $text-color;
      }
      .form-submit-button {
        .form-submit-label {
          color: $text-color-dark;
        }
        .form-submit-arrows {
          background-color: $text-color-dark;
        }
      }
    }
  }
  
  @include hover {
    &:hover {
      .form-submit-content {
        .form-submit-button {
          .form-submit-label {
            color: $text-color;
          }
          .form-submit-arrows {
            background-color: $text-color;
          }
        }
      }
    }
  }
}
@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.SaveLocationButton {
	position: absolute;
	right: 25px;
	bottom: 22px;
	width: 17px;
	height: 16px;
	cursor: pointer;

	.icon {
		@include fill;
		transition: $background-color-transition, $opacity-transition;
		background-color: $color-gold;
		-webkit-mask: url(../../../images/icons/saved.svg) no-repeat center;
		mask: url(../../../images/icons/saved.svg) no-repeat center;
	}

	.icon-saved {
		@include fill;
		transition: $background-color-transition, $opacity-transition;
		background-color: $text-color;
		opacity: 0;
		-webkit-mask: url(../../../images/icons/saved-filled.svg) no-repeat center;
		mask: url(../../../images/icons/saved-filled.svg) no-repeat center;
	}

	&.large {
		transform-origin: center;
		transform: scale(1.25);
	}

	&.dark-theme {
		right: 12px;
		bottom: 14px;
		&:not(.saved) {
			.icon {
				background-color: $text-color-dark;
			}
		}
	}

	&.relative {
		position: relative;
		right: unset;
		bottom: unset;
		flex-shrink: 0;
	}

	&.saved {
		.icon {
			opacity: 0;
		}

		.icon-saved {
			opacity: 1;
		}
	}
}

@include hover {
	.SaveLocationButton:hover {
		.save-location-tooltip {
			opacity: 1;
		}

		.icon {
			background-color: $text-color;
		}

		.icon-saved {
			background-color: $text-color;
		}

		&.saved {
			.icon {
				background-color: $color-gold;
			}

			.icon-saved {
				background-color: $color-gold;
			}

			&.dark-theme {
				.icon {
					background-color: $text-color-dark;
				}
			}
		}

		&.dark-theme {
			.icon {
				background-color: $text-color;
			}
		}
	}
}

@import '../../../../../scss/constants.scss';
@import '../../../../../scss/mixins.scss';

.AboutImage {
  display: block;
  width: 100%;
  margin: 25px 0;
  overflow: hidden;
  > img {
    display: block;
    width: 100%;
  }
}
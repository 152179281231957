@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.Intro {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// z-index: 100;
	z-index: $layer-ui-3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	.intro-pre-load__bg {
		@include fill;
		background-repeat: no-repeat;
		background-position: center center;		
		background-color: $background-color;		
		background-size:cover;
		&.barber{
			background-image: url('../../../images/intro/Bem_Intro_Barber.jpg');
		}
		&.cowboy{
			background-image: url('../../../images/intro/Bem_Intro_Cowboy.jpg');
		}
		&.fashion{
			background-image: url('../../../images/intro/Bem_Intro_Fashion.jpg');
		}
		&.grafitti{
			background-image: url('../../../images/intro/Bem_Intro_Grafitti.jpg');
		}
		&.medium{
			background-image: url('../../../images/intro/Bem_Intro_Medium.jpg');
		}
		&.restaurant{
			background-image: url('../../../images/intro/Bem_Intro_Restaurant.jpg');
		}
	}

	.intro-pre-load {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		height: calc(100% - 40px);
		//max-height: 625px;
		width: 100%;
	}

	.intro-bab-logo__lock {
		
		position: relative;
		text-align: center;
		.presents {
			color: $text-color;
			font-family: $font-family-william;
			text-transform: uppercase;
			font-size: 16px;
			margin-top: 17px;
			display: none;
			margin-bottom: 90px;
		}
	}

	.intro-bem-logo__lock {
		
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
		margin-bottom: 10px;
		width: 100%;
		display: none;
	}

	.intro-bab-logo__wrap {
		display: block;
		margin: 0 auto 10px;
		padding-left: 30px;

		@include mediaQuery(lrg) {
			padding-left: 42px;
		}
	}

	.intro-bab-logo {
		position: relative;
		width: 200px;
		height: 78px;
		background-color: #fff;
		-webkit-mask: url(../../../images/landing/black-and-abroad-logo.svg) no-repeat center;
		mask: url(../../../images/landing/black-and-abroad-logo.svg) no-repeat center;
	}

	.intro-bem-logo {
		position: relative;
		width: calc(100% - 48px);
		max-width: 450px;
		height: 100%;
		margin: 0 auto;
		background-color: #fff;
		-webkit-mask: url(../../../images/landing/bem-logo.svg) no-repeat center;
		mask: url(../../../images/landing/bem-logo.svg) no-repeat center;
	}

	.intro-load-spin {
		position: absolute;
		bottom: $margin-vertical;
		right: $margin-horizontal;
		width: 25px;
		height: 25px;
		border-color: $text-color;
		border-style: solid;
		border-width: 4px;
		transform: rotate(45deg);
		animation-duration: 1s;
		animation-name: load-spin;
		animation-delay: 0.6s;
		animation-iteration-count: infinite;
	}

	@include mediaQuery(mobile) {
		.intro-pre-load {
			max-height: 460px;
		}
		.intro-bab-logo {
			width: 150px;
			height: 65px;
		}
		.intro-bab-logo__lock {
			.presents {
				margin-bottom: 30px;
			}
		}
	}
}

@keyframes load-spin {
	0% {
		transform: rotate(45deg);
	}
	100% {
		transform: rotate(405deg);
	}
}

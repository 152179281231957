@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.SearchBarMapbox {
	height: 100%;
	width: 100%;

	.search-icon {
		position: absolute;
		right: 0;
		top: -2px;
		width: 24px;
		height: 100%;
		-webkit-mask: url(../../../images/icons/search.svg) no-repeat center;
		mask: url(../../../images/icons/search.svg) no-repeat center;
		background-color: $text-color;
		-webkit-mask-size: contain;
		mask-size: contain;
	}

	.geocoder-dropdown-item {
		padding: 20px;
		width: 100%;
	}

	.geocoder-dropdown-text {
		font-family: inherit;
		font-size: 18px;
		line-height: 18px;
		color: inherit;
		display: flex;
		align-items: center;

		@include mediaQuery(lrg) {
			font-size: 14px;
		}

		span.poi-name {
			margin-right: 5px;
		}

		span.poi-address {
			// color: #aaaaaa;
			opacity: 0.5;
		}
	}

	.result-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: -10px;

		img {
			width: 50px;
			height: 50px;
		}
	}

	/* Basics */
	.mapboxgl-ctrl-geocoder,
	.mapboxgl-ctrl-geocoder *,
	.mapboxgl-ctrl-geocoder *:after,
	.mapboxgl-ctrl-geocoder *:before {
		box-sizing: border-box;
	}

	.mapboxgl-ctrl-geocoder {
		font-size: 50px;
		line-height: 50px;
		font-family: $font-family-martin;
		text-transform: uppercase;
		position: relative;
		background-color: transparent;
		color: $text-color;
		width: 100%;
		min-width: 240px;
		z-index: 1;
		transition: width 0.25s, min-width 0.25s;
		border-bottom: 1px solid $text-color;
	}

	.mapboxgl-ctrl-geocoder--input {
		font: inherit;
		font-size: inherit;
		text-transform: uppercase;
		line-height: 50px;
		width: calc(100% - 34px);
		border: 0;
		background-color: transparent;
		margin: 0;
		height: 100%;
		color: inherit;
		border-radius: 0;
		// padding: 6px 45px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding: 0 0 10px 0;

		// @include mediaQuery(lrg) {
		// 	// padding: 0;
		// }
	}

	.mapboxgl-ctrl-geocoder--input::-ms-clear {
		display: none; /* hide input clear button in IE */
	}

	.mapboxgl-ctrl-geocoder--input::placeholder {
		color: $text-color;
	}

	.mapboxgl-ctrl-geocoder--input:focus {
		color: inherit;
		outline: none !important;
		box-shadow: none;
	}

	.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
		z-index: 2;
		position: absolute;
		right: 8px;
		top: 7px;
		display: none;
	}

	// .mapboxgl-ctrl-geocoder,
	// .mapboxgl-ctrl-geocoder .suggestions {
	// 	// box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
	// }

	/* Collapsed */
	.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
		width: 50px;
		min-width: 50px;
		transition: width 0.25s, min-width 0.25s;
	}

	/* Suggestions */
	.mapboxgl-ctrl-geocoder .suggestions {
		left: -40px;
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		width: calc(100% + 80px);
		top: calc(100% + 21px);
		z-index: 1000;
		overflow: hidden;
		font-family: $font-family-william;
		font-size: 14px;
		text-transform: capitalize;
		line-height: 16px;
	}

	.mapboxgl-ctrl-bottom-left .suggestions,
	.mapboxgl-ctrl-bottom-right .suggestions {
		top: auto;
		bottom: 100%;
	}

	.mapboxgl-ctrl-geocoder .suggestions > li > a {
		cursor: default;
		display: block;
		color: $text-color;
		background: transparent;
		padding: 0 20px;

		@include mediaQuery(lrg) {
			background: rgba(32, 31, 33, 0.9);
			padding: 5px 25px;
		}
	}

	.mapboxgl-ctrl-geocoder .suggestions > .active > a,
	.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
		background-color: $color-gold;
		text-decoration: none;
		cursor: pointer;
	}

	.mapboxgl-ctrl-geocoder--suggestion-title {
		font-weight: bold;
	}

	.mapboxgl-ctrl-geocoder--suggestion-title,
	.mapboxgl-ctrl-geocoder--suggestion-address {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	/* Icons */
	.mapboxgl-ctrl-geocoder--icon {
		display: inline-block;
		vertical-align: middle;
		speak: none;
		fill: #757575;
		top: 15px;
	}

	.mapboxgl-ctrl-geocoder--icon-search {
		position: absolute;
		top: 13px;
		left: 12px;
		width: 23px;
		height: 23px;
		display: none;
	}

	.mapboxgl-ctrl-geocoder--button {
		padding: 0;
		margin: 0;
		border: none;
		cursor: pointer;
		background: #fff;
		line-height: 1;
	}

	.mapboxgl-ctrl-geocoder--icon-close {
		width: 20px;
		height: 20px;
		margin-top: 8px;
		margin-right: 3px;
		display: none;
	}

	.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
		fill: #909090;
	}

	.mapboxgl-ctrl-geocoder--icon-loading {
		width: 26px;
		height: 26px;
		margin-top: 5px;
		margin-right: 0px;
		-moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
		-webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
		animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
	}

	.mapbox-gl-geocoder--error {
		font-size: 18px;
		cursor: default;
		display: block;
		color: $text-color;
		background: transparent;
		padding: 0 40px;
		border: 0;

		@include mediaQuery(lrg) {
			background: rgba(32, 31, 33, 0.9);
			padding: 20px 40px;
			font-size: 14px;
		}
	}

	/* Animation */
	@-webkit-keyframes rotate {
		from {
			-webkit-transform: rotate(0);
			transform: rotate(0);
		}
		to {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	@keyframes rotate {
		from {
			-webkit-transform: rotate(0);
			transform: rotate(0);
		}
		to {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	/* Media queries*/
	@media screen and (min-width: 640px) {
		.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
			width: 36px;
			min-width: 36px;
		}

		.mapboxgl-ctrl-geocoder {
			width: 100%;
			font-size: 50px;
			line-height: 20px;
			// max-width: 360px;
			text-transform: uppercase;
		}
		.mapboxgl-ctrl-geocoder .suggestions {
			font-size: 18px;
		}

		.mapboxgl-ctrl-geocoder--icon {
			top: 8px;
		}

		.mapboxgl-ctrl-geocoder--icon-close {
			width: 16px;
			height: 16px;
			margin-top: 3px;
			margin-right: 0;
			display: none;
		}

		.mapboxgl-ctrl-geocoder--icon-search {
			left: 7px;
			width: 20px;
			height: 20px;
		}

		// .mapboxgl-ctrl-geocoder--input {
		// 	height: 100%;
		// 	// padding: 6px 35px;
		// }

		.mapboxgl-ctrl-geocoder--icon-loading {
			width: 26px;
			height: 26px;
			margin-top: -2px;
			margin-right: -5px;
		}

		// .mapbox-gl-geocoder--error {
		// 	// color: red;
		// 	// padding: 6px 12px;
		// 	// font-size: 16px;
		// 	// text-align: center;

		// 	font-size: 14px;
		// 	cursor: default;
		// 	display: block;
		// 	color: $text-color;
		// 	background: transparent;
		// 	padding: 20px 40px;
		// 	border: 0;

		// 	@include mediaQuery(lrg) {
		// 		background: rgba(32, 31, 33, 0.9);
		// 		padding: 20px 40px;
		// 	}
		// }
	}
}

@import '../../../../../scss/constants.scss';
@import '../../../../../scss/mixins.scss';

.AboutIcons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 50px;
  width: 100%;
  margin: 25px 0 35px;
  overflow: hidden;
  
  .icon-el {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-image {
    width: 120px;
  }

  .icon-info {
    flex: 1;
  }

  .icon-desc {
    max-width: 280px;
  }

  .icon-icon-label {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 4px;
  }

  @include mediaQuery(mobile) {
    grid-template-columns: 1fr;
  }
  
}
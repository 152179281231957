// colors
$background-color: #000;
$text-color: #fff;
$text-color-dark: #000;
$text-color-dimmed: rgba(255, 255, 255, 0.75);
$color-gold: #af997e;
$gold: #af997e;
$color-almost-black: #0e0c0f;
$scrollbar-color: #af997e;
$color-curated: #af997e;
$color-entertainment: #988780;
$color-see: #c2927e;
$color-beauty: #dac4aa;
$color-shop: #a1a0a2;
$color-stay: #9fadaf;
$color-learn: #bfc1b1;
$color-eat: #f0e0b8;
$color-flex: #dbdbdb;
$color-general: #ffffff;

$icon-base-size: 30px;
$cluster-icon-base-size: calc(#{$icon-base-size} * 1.8);
$cluster-icon-label-height: calc(#{$cluster-icon-base-size} + 1px);

// fonts
$font-family-william: 'VTCWilliam', 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$font-family-martin: 'VTC Martin', 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;

$margin-vertical: 50px;
$margin-horizontal: 40px;
$margin-vertical-mobile: 20px;
$margin-horizontal-mobile: 20px;

$layer-base-1: 0;
$layer-base-2: 1;
$layer-content: 2;
$layer-ui-1: 3;
$layer-ui-2: 4;
$layer-ui-3: 5;
$layer-ui-4: 6;
$layer-max: 7;
$layer-rotate-message: 999;
$layer-consent: 102;

$ease-in-out-quad: cubic-bezier(0.45, 0, 0.55, 1);
$ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
$ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);
$ease-in-out-expo: cubic-bezier(0.87, 0, 0.13, 1);
$ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);

$color-transition: color 0.2s ease-out;
$background-color-transition: background-color 0.2s ease-out;
$border-color-transition: border-color 0.2s ease-out;
$opacity-transition: opacity 0.5s ease-out;
$transform-transition: transform 1s $ease-in-out-quint;

/*New Colours*/
$green: #43DF8B;
$text-green: $green;
$color-gold: $green;
$scrollbar-color: $green;

$color-eat:#FD5353;
$color-entertainment:#95EFBF;
$color-shop:#FFD363;
$color-see:#B5E9DC;
$color-learn:#FBB80D;
$color-stay:#F9E8BE;
$color-beauty:#F48759;
$color-flex:#F9CEBD;
$color-curated:#0CE570;
$favorites:#af997e;
$general:#af997e;
@import '../../../../../scss/constants.scss';
@import '../../../../../scss/mixins.scss';

.AboutContributors {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 32px;
	column-gap: 20px;

	@include mediaQuery(tablet) {
		grid-template-columns: 1fr;
	}

	@include mediaQuery(mobile) {
		grid-template-columns: 1fr;
	}

	.contributor {
		display: flex;
		flex-direction: row;
		overflow: hidden;
	}

	.contributor-image {
		position: relative;
		height: 100%;
		width: 128px;
		min-height: 226px;
		> div {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: 50% 20%;
			background-repeat: no-repeat;
			background-size: cover;
		}
		@include mediaQuery(tablet) {
			width: 180px;
		}
	}

	.contributor-info {
		flex: 1;
		padding: 0 10px 0 30px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		.name {
			font-family: $font-family-william;
			font-size: 20px;
			line-height: 22px;
			letter-spacing: 0.03em;
		}

		.description {
			flex: 1;
			font-family: $font-family-william;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.03em;
			margin: 20px 0;
		}
	}

	.social-link {
		display: inline-block;
		background-color: $text-color;
		transition: $background-color-transition;
		margin-right: 12px;
		height: 20px;
	}

	.tiktok-link {
		width: 16px;
		-webkit-mask: url(../../../../../images/icons/tiktok.svg) no-repeat center;
		mask: url(../../../../../images/icons/tiktok.svg) no-repeat center;
	}

	.twitter-link {
		width: 16px;
		-webkit-mask: url(../../../../../images/icons/twitter.svg) no-repeat center;
		mask: url(../../../../../images/icons/twitter.svg) no-repeat center;
	}

	.instagram-link {
		width: 16px;
		-webkit-mask: url(../../../../../images/icons/instagram.svg) no-repeat center;
		mask: url(../../../../../images/icons/instagram.svg) no-repeat center;
	}

	.facebook-link {
		width: 20px;
		-webkit-mask: url(../../../../../images/icons/facebook.svg) no-repeat center;
		mask: url(../../../../../images/icons/facebook.svg) no-repeat center;
	}

	.linkedin-link {
		width: 20px;
		-webkit-mask: url(../../../../../images/icons/linkedin.svg) no-repeat center;
		mask: url(../../../../../images/icons/linkedin.svg) no-repeat center;
	}

	.youtube-link {
		width: 20px;
		-webkit-mask: url(../../../../../images/icons/youtube.svg) no-repeat center;
		mask: url(../../../../../images/icons/youtube.svg) no-repeat center;
	}

	@media only screen and (max-width: 420px) {
		.contributor {
			flex-direction: column;
		}

		.contributor-image {
			height: 200px;
			width: 100%;
			min-height: unset;
		}

		.contributor-info {
			padding: 10px 0 0;
		}
	}

	@include hover {
		.social-link:hover {
			background-color: $color-gold;
		}
	}
}

@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.FlagModal {
	@include fill;
	pointer-events: none;

	& > * {
		pointer-events: auto;
	}

	.modal-container {
		@include center;
		background-color: $color-almost-black;
		border: 1px solid $color-gold;
		width: 300px;
		box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
		padding: 30px 20px;
		display: flex;
		flex-direction: column;

		.modal-header {
			font-family: $font-family-william;
			font-size: 20px;
			line-height: 22px;
			color: $text-color;
			margin-bottom: 30px;
		}

		.modal-content {
			.desc {
				font-family: $font-family-william;
				font-size: 14px;
				line-height: 20px;
				color: $text-color;
				letter-spacing: 0.03em;
				margin-bottom: 15px;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.button {
					background-color: $color-gold;
					transition: $background-color-transition;
					cursor: pointer;

					&.yelp {
						height: 18px;
						width: 43px;
						-webkit-mask: url(../../../images/icons/logo-yelp.svg) no-repeat center;
						mask: url(../../../images/icons/logo-yelp.svg) no-repeat center;
					}

					&.google {
						height: 17px;
						width: 51px;
						-webkit-mask: url(../../../images/icons/logo-google.svg) no-repeat center;
						mask: url(../../../images/icons/logo-google.svg) no-repeat center;
					}
				}

				.next-arrows {
					width: 9px;
					height: 10px;
					background-color: $color-gold;
					transition: $background-color-transition;
					margin-left: 10px;
					-webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center / 100%;
					mask: url(../../../images/icons/next-arrow.svg) no-repeat center / 100%;

					&.yelp {
						margin-top: 3px;
					}

					&.google {
						margin-top: 1px;
					}
				}
			}

			@include hover {
				a:hover {
					.button,
					.next-arrows {
						background-color: $text-color;
					}
				}
			}
		}
	}
}

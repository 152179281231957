@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

$padding-h: 75px;
$padding-v: 75px;

.HamburgerMenu {
	position: absolute;
	background: #0e0c0f;
	z-index: $layer-ui-4;
	overflow: none;
	@include mediaQuery(sml) {
		@include fill;
		z-index: $layer-content;
	}
	@include mediaQuery(lrg) {
		top: 25px;
		@include center-x;
		width: calc(100% - #{$padding-h * 2 + 150px});
		height: calc(100% - #{$padding-v * 2});
		background: rgba(32, 31, 33, 0.9);
	}
	@include mediaQuery(tablet) {
		width: calc(100% - #{$margin-horizontal * 2});
	}

	.menu-item {
		display: block;
		font-family: $font-family-martin;
		font-size: 60px;
		line-height: 1;
		color: $text-color;
		text-transform: uppercase;
		margin-bottom: 0px;
		overflow: hidden;
		cursor: pointer;
		transition: $color-transition;
		> div {
			display: block;
			width: 100%;
		}
		@include hover {
			&:hover {
				color: $color-gold;
			}
		}

		@include mediaQuery(largeDesktop) {
			--max-font: 140;
			--min-font: 70;
			font-size: var(--responsive);
			line-height: 0.75;
		}

		@include mediaQuery(largeTablet) {
			--max-font: 140;
			--min-font: 70;
			font-size: var(--responsive);
			line-height: 0.75;
		}

		@include mediaQuery(tablet) {
			--max-font: 140;
			--min-font: 100;
			font-size: var(--responsive);
		}
		@media only screen and (min-width: 1350px) {
			font-size: calc(var(--max-font) * 1px);
		}
	}

	.pad-container {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		position: relative;
		height: 100%;
		padding: 120px 20px 80px;
		@include mediaQuery(lrg) {
			margin-top: unset;
			padding: $padding-v $padding-h;
		}
	}

	.scroll-container {
		display: block;
		width: 100%;
		flex: 1;
		overflow-y: auto;
		padding-right: 20px;
	}
	.HamburgerMenu-inner {
		display: block;
		position: relative;
		height: 100%;
	}
	.AddToMap-copy {
		@include mediaQuery(tablet) {
			height: calc(100% - 80px);
		}
	}
	.title {
		font-family: $font-family-martin;
		font-size: 42px;
		line-height: 28px;
		color: white;
		text-transform: uppercase;
		margin-bottom: 30px;
		@include mediaQuery(lrg) {
			color: $color-gold;
			font-size: 50px;
			line-height: 34px;
		}
	}
	.sub-header {
		font-family: $font-family-william;
		font-size: 28px;
		line-height: 30px;
		color: $text-color;
		margin-bottom: 30px;
	}
	.HamburgerMenu-copy {
		max-width: 500px;
	}
	h2,
	h3,
	a,
	p {
		color: white;
	}
	.external-link {
		font-family: $font-family-william;
		font-size: 18px;
		letter-spacing: 0.02em;
		color: $green;
		transition: $color-transition;
		text-decoration: none;
		text-transform: uppercase;
		margin-right: 4px;
		position: relative;
		padding-right: 30px;
		&::after {
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.83366 7.5H2.16699V20.8333H15.5003V14.1667H17.0003V20.8333V22.3333H15.5003H2.16699H0.666992V20.8333V7.5V6H2.16699H8.83366V7.5Z' fill='%2343DF8B'/%3E%3Cline x1='18.1133' y1='4.78033' x2='7.61334' y2='15.2803' stroke='%2343DF8B' stroke-width='1.5'/%3E%3Cpath d='M14.8723 2.46579C15.0521 2.36147 15.2674 2.33262 15.4671 2.38589L19.1714 3.3691C19.4422 3.44013 19.6552 3.65319 19.7263 3.92397L20.7161 7.61713C20.7716 7.8191 20.7428 8.03439 20.6384 8.21417L19.6486 9.92758C19.6131 9.98529 19.5709 10.0408 19.5221 10.0896L16.8188 12.7929L15.709 11.6832L18.3391 9.05312L19.1137 7.71035L18.5588 5.64626L17.4491 4.53654L15.3784 3.98389L14.0445 4.75848L11.4144 7.38853L10.3047 6.27881L13.008 3.57552C13.0546 3.52891 13.1079 3.48452 13.1678 3.45123L14.8723 2.46579Z' fill='%2343DF8B'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			right: 0;
			top: -4px;
			width: 23px;
			height: 23px;
		}

		@include hover {
			&:hover {
				color: white !important;
				&::after {
					background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.83366 7.5H2.16699V20.8333H15.5003V14.1667H17.0003V20.8333V22.3333H15.5003H2.16699H0.666992V20.8333V7.5V6H2.16699H8.83366V7.5Z' fill='%23FFFFFF'/%3E%3Cline x1='18.1133' y1='4.78033' x2='7.61334' y2='15.2803' stroke='%23FFFFFF' stroke-width='1.5'/%3E%3Cpath d='M14.8723 2.46579C15.0521 2.36147 15.2674 2.33262 15.4671 2.38589L19.1714 3.3691C19.4422 3.44013 19.6552 3.65319 19.7263 3.92397L20.7161 7.61713C20.7716 7.8191 20.7428 8.03439 20.6384 8.21417L19.6486 9.92758C19.6131 9.98529 19.5709 10.0408 19.5221 10.0896L16.8188 12.7929L15.709 11.6832L18.3391 9.05312L19.1137 7.71035L18.5588 5.64626L17.4491 4.53654L15.3784 3.98389L14.0445 4.75848L11.4144 7.38853L10.3047 6.27881L13.008 3.57552C13.0546 3.52891 13.1079 3.48452 13.1678 3.45123L14.8723 2.46579Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
				}
			}
		}
	}
}

.HamburgerMenu {
	* {
		--responsive: calc(
			(var(--min-font) * 1px) + (var(--max-font) - var(--min-font)) * ((100vw - 770px) / (1350 - 770))
		); /* Ranges from 770px to 1350px */
	}
}

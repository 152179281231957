@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

.OnBoarding {
	@include fill;
	overflow: hidden auto;
	z-index: 101;
	background-color: $text-color-dark;

	&.no-scroll {
		overflow: hidden;
	}

	// &::-webkit-scrollbar-track {
	// 	background-color: $text-color-dark;
	// }

	// &::-webkit-scrollbar-thumb {
	// 	background-color: $scrollbar-color;
	// 	min-height: 40px;
	// }

	.content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.arrow {
			width: 7px;
			height: 24px;
			background-color: $color-gold;
			margin-bottom: 18px;
			-webkit-mask: url(../../../images/icons/down-arrow-alt.svg) no-repeat center;
			mask: url(../../../images/icons/down-arrow-alt.svg) no-repeat center;

			&.lrg-gap {
				margin-top: 42px;
				margin-bottom: 60px;
			}
		}

		.learn-more-btn {
			display: flex;
			align-items: center;

			.label {
				font-family: $font-family-william;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: 0.02em;
				color: $color-gold;
				text-transform: uppercase;
				margin-right: 4px;
			}

			.arrows {
				width: 13px;
				height: 14px;
				background-color: $color-gold;
				-webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center / 100%;
				mask: url(../../../images/icons/next-arrow.svg) no-repeat center / 100%;
			}

			&.dark {
				.label {
					color: $text-color-dark;
				}
				.arrows {
					background-color: $text-color-dark;
				}
			}
		}

		.scroll-message {
			color: $color-gold;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 25px;
			transition: $opacity-transition;

			&.hidden {
				opacity: 0;
			}

			.header {
				width: calc(100% - 50px);
				font-family: $font-family-martin;
				font-size: 32px;
				line-height: 26px;
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 16px;
			}

			.subheader {
				width: calc(100% - 50px);
				font-family: $font-family-william;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.03em;
				text-align: center;
			}

			&.dark {
				color: $text-color-dark;

				.arrow {
					background-color: $text-color-dark;
				}
			}
		}

		.content-section {
			position: relative;
			width: 100%;
			// height: 100%;
		}

		.landing {
			width: calc(100% - 50px);
			height: 100%;
			padding: 0 25px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			.loader-spin {
				position: absolute;
				bottom: $margin-vertical;
				left: 50%;
				width: 30px;
				height: 30px;
				border-color: $text-color;
				border-style: solid;
				border-width: 4px;
				transform: translateX(-50%) rotate(45deg);
				animation-duration: 1s;
				animation-name: loader-spin;
				animation-delay: 0.6s;
				animation-iteration-count: infinite;
			}

			.header {
				margin-top: 44px;

				.baa {
					width: 104px;
					height: 40px;
					background-color: $text-color;
					margin-left: 20px;

					-webkit-mask: url(../../../images/landing/black-and-abroad-logo.svg) no-repeat center;
					mask: url(../../../images/landing/black-and-abroad-logo.svg) no-repeat center;
				}

				.presents {
					font-family: $font-family-william;
					font-size: 14px;
					line-height: 16px;
					color: $text-color;
					text-transform: uppercase;
					letter-spacing: 0.03em;
					text-align: center;
					margin-top: 10px;
				}
			}

			.bem {
				width: 255px;
				height: 225px;
				background-color: $text-color;
				margin-top: -36px;
				-webkit-mask: url(../../../images/landing/bem-logo.svg) no-repeat center;
				mask: url(../../../images/landing/bem-logo.svg) no-repeat center;
			}
		}

		.about {
			padding-bottom: 46px;

			.header {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0 18px;
				margin-bottom: 30px;

				.title {
					font-family: $font-family-martin;
					font-size: 56px;
					line-height: 42px;
					color: $color-gold;
					text-transform: uppercase;
				}
			}

			.media-embed {
				width: 100%;
				height: 212px;
				overflow: hidden;
				margin-bottom: 15px;
			}

			.footer {
				padding: 0 18px;
				display: block;

				.copy {
					font-family: $font-family-william;
					font-size: 14px;
					line-height: 16px;
					color: $text-color;
					margin-bottom: 15px;
				}
			}
		}

		.bottom {
			background-color: #bab8b0;
			background: bottom / contain no-repeat url(../../../images/landing/mountain-footer.png), #bab8b0;
			padding-top: 18px;

			.learn-more-btn {
				padding: 25px 0;
			}

			.footer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 100px 18px 20px 18px;
				font-family: $font-family-william;
				font-size: 11px;
				line-height: 16px;
				letter-spacing: 0.03em;
				color: $text-color;

				a {
					font-family: inherit;
					font-size: inherit;
					color: inherit;
					text-decoration: none;
				}

				.instagram {
					width: 14px;
					height: 14px;
					background-color: $text-color;
					-webkit-mask: url(../../../images/icons/instagram.svg) no-repeat center / contain;
					mask: url(../../../images/icons/instagram.svg) no-repeat center / contain;
				}

				.twitter {
					width: 20px;
					height: 13px;
					background-color: $text-color;
					-webkit-mask: url(../../../images/icons/twitter.svg) no-repeat center / contain;
					mask: url(../../../images/icons/twitter.svg) no-repeat center / contain;
				}
			}
		}

		.guide {
			.guide-image {
				position: absolute;
				width: 100%;
				// height: 40%;
				height: 324px;
				z-index: 0;
				background-color: $color-almost-black;

				img {
					object-fit: cover;
				}

				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.9) 95%);
				}
			}

			.guide-content {
				padding: 18px;
				display: flex;
				flex-direction: column;
				position: relative;
				width: calc(100% - 36px);
				height: calc(100% - 36px);
				z-index: 1;
				color: $text-color;

				&::before {
					content: '';
					position: relative;
					height: 220px;
					// height: calc(40% - 90px);
				}

				.title {
					font-family: $font-family-martin;
					font-size: 56px;
					line-height: 56px;
					color: $color-gold;
					text-transform: uppercase;
				}

				.description {
					font-family: $font-family-william;
					font-size: 14px;
					line-height: 16px;
					color: $color-gold;
					margin-bottom: 37px;
				}

				.view-map-button {
					width: 100%;
					height: 30px;
					text-align: center;
					border: 1px solid $color-gold;
					background-color: $text-color-dark;
					margin: 30px 0 15px 0;
					display: flex;
					align-items: center;
					justify-content: center;

					.icon {
						width: 24px;
						height: 24px;
						background-color: $color-gold;
						-webkit-mask: url(../../../images/icons/see-map.svg) no-repeat center;
						mask: url(../../../images/icons/see-map.svg) no-repeat center;
						margin-right: 5px;
					}

					.label {
						font-family: $font-family-william;
						font-size: 12px;
						line-height: 12px;
						letter-spacing: 0.02em;
						text-transform: uppercase;
						color: $color-gold;
					}
				}

				.poi-container {
					display: flex;
					justify-content: space-between;

					.poi-item {
						flex: 1;
						font-family: $font-family-william;
						color: $text-color;

						&:first-of-type {
							margin-right: 20px;
						}

						.poi-image {
							height: 72px;
							background-color: $color-almost-black;
							margin-bottom: 15px;
						}

						img {
							object-fit: cover;
						}

						.poi-title {
							font-family: $font-family-martin;
							font-size: 26px;
							line-height: 20px;
							text-transform: uppercase;
							color: $text-color;
							margin-bottom: 15px;
						}

						.poi-subtitle {
							font-size: 16px;
							line-height: 18px;
							color: $text-color;
							margin-bottom: 15px;
						}

						.poi-description {
							font-size: 13.5px;
							line-height: 15px;
							color: $text-color;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}
}

@keyframes loader-spin {
	0% {
		transform: translateX(-50%) rotate(45deg);
	}
	100% {
		transform: translateX(-50%) rotate(405deg);
	}
}

@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.Home {
	@include center;
	filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
	z-index: $layer-ui-2;
	display: flex;
	flex-direction: column;
	align-items: center;
	pointer-events: none;
	width: 78%;

	@include mediaQuery(lrg) {
		width: 500px;
	}

	.title {
		font-family: $font-family-martin;
		font-size: 42px;
		line-height: 28px;
		text-transform: uppercase;
		text-align: center;
		color: $text-color;
		margin-bottom: 20px;

		@include mediaQuery(lrg) {
			font-size: 80px;
			line-height: 56px;
		}
	}

	.description {
		font-family: $font-family-william;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.03em;
		text-align: center;
		color: $text-color;

		@include mediaQuery(lrg) {
			font-size: 14px;
			line-height: 20px;
			max-width: 300px;
		}
	}

	.start-btn {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		pointer-events: auto;

		@include mediaQuery(lrg) {
			margin-bottom: 44px;
		}

		.label {
			font-family: $font-family-martin;
			font-size: 30px;
			line-height: 34px;
			color: $color-gold;
			text-transform: uppercase;
			margin-right: 7px;
			transition: $color-transition;

			@include mediaQuery(lrg) {
				font-size: 50px;
				line-height: 50px;
			}
		}

		svg {
			margin-top: 3px;

			path {
				fill: $color-gold;
				transition: fill 0.2s ease-out;
			}
		}
	}

	@include hover {
		.start-btn:hover {
			div {
				color: $text-color;
			}

			svg {
				path {
					fill: $text-color;
				}
			}
		}
	}
}

@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

$height: 210px;

.DataSelector {
	position: absolute;
	right: 0;
	bottom: 100px;
	z-index: $layer-base-2;
	width: 100%;
	height: 200px;
	pointer-events: none;

	@include mediaQuery(smallMobile) {
		bottom: 85px;
	}

	@include mediaQuery(lrg) {
		left: 50%;
		margin-left: -347px;
		bottom: 0;
		width: 694px;
		height: 284px;
		overflow: hidden;
		z-index: $layer-ui-2;
	}

	.mobile > * {
		pointer-events: auto;
	}

	.desktop > * {
		pointer-events: auto;
	}

	.mobile {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		pointer-events: none;

		.selector {
			width: 100%;
			padding: 0 $margin-horizontal-mobile;
			box-sizing: border-box;
			margin-top: auto;

			.options {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.option {
					display: flex;
					flex-direction: column;
					font-family: $font-family-william;
					font-size: 10px;
					line-height: 11px;
					color: var(--nav-color-bc);
					align-items: center;
					transition: $color-transition;
					text-align: center;
					white-space: pre-line;
					margin-bottom: auto;
					width: 18%;

					&.active {
						.icon {
							background-color: $color-gold;
							border-color: $color-gold;
						}
					}

					&.disabled {
						pointer-events: none;
					}

					.icon {
						width: 16px;
						height: 16px;
						border: 2px solid var(--nav-color-bc);
						transform: rotate(45deg);
						margin-bottom: 10px;
						transition: $background-color-transition;
					}
				}
			}
		}

		.info-panel {
			width: calc(100% - #{$margin-horizontal-mobile * 2});
			padding: 30px 20px;
			background-color: $color-gold;
			margin-bottom: 20px;
			box-sizing: border-box;
			margin-top: auto;

			.title {
				font-family: $font-family-william;
				font-size: 16px;
				line-height: 16px;
				color: $text-color-dark;
			}

			.content {
				font-family: $font-family-william;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.03em;
				color: $text-color-dark;
			}

			.bottom-arrow {
				position: absolute;
				left: 0;
				bottom: -7px;
				width: 20px;
				height: 20px;
				transform-origin: center;
				transform: translateX(-100%) rotate(45deg);
				background-color: $color-gold;
			}

			.CloseButtonX {
				pointer-events: auto;
			}
		}
	}

	.desktop {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		pointer-events: none;

		.selector {
			position: relative;
			width: 445px;
			box-sizing: border-box;
			margin-top: auto;
			transform: translateY(-116px);
			transition: $transform-transition;

			&.panel-visible {
				pointer-events: none;

				.options {
					.option {
						color: $text-color;

						.option-inner {
							pointer-events: auto;
							transform: translateY(-92px);

							.icon {
								border-color: $text-color;
							}
						}

						&.active {
							color: $text-green;
							pointer-events: none;

							.option-inner {
								.icon {
									background-color: $green;
									border-color: $green;
								}
							}
						}

						@for $i from 1 through 5 {
							&:nth-child(#{$i}) {
								.option-inner {
									transition-delay: #{$i * 0.04}s;
								}
							}
						}
					}

					@include hover {
						.option:hover {
							color: $green;

							.option-inner {
								.icon {
									border-color: $green;
								}
							}

							&.active {
								.option-inner {
									.icon {
										background-color: $green;
									}
								}
							}
						}
					}
				}
			}

			.options {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.option {
					font-family: $font-family-william;
					font-size: 10px;
					line-height: 12px;
					color: var(--nav-color-bc);
					transition: $color-transition;
					text-align: center;
					white-space: pre-line;
					margin-bottom: auto;
					width: 18%;
					cursor: pointer;

					&.active {
						.option-inner {
							.icon {
								background-color: $green;
								border-color: $green;
								// background-color: var(--nav-color-bc);
								// border-color: var(--nav-color-bc);
							}
						}
					}

					&.disabled {
						pointer-events: none;
					}

					.option-inner {
						display: flex;
						flex-direction: column;
						align-items: center;
						transition: transform 0.45s $ease-in-out-quad;

						.icon {
							width: 16px;
							height: 16px;
							border: 2px solid var(--nav-color-bc);
							transform: rotate(45deg);
							margin-bottom: 10px;
							transition: $background-color-transition, $border-color-transition;
						}
					}

					@for $i from 1 through 5 {
						&:nth-child(#{$i}) {
							.option-inner {
								transition-delay: #{0.15 + $i * 0.04}s;
							}
						}
					}
				}

				@include hover {
					.option:hover {
						color: var(--nav-color-bc-hover);

						.option-inner {
							.icon {
								border-color: var(--nav-color-bc-hover);
							}
						}

						&.active {
							.option-inner {
								.icon {
									background-color: var(--nav-color-bc-hover);
								}
							}
						}
					}
				}
			}
		}

		.info-panel {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: rgba(32, 31, 33, 0.9);

			.info-panel-content {
				height: 100%;

				.content {
					display: flex;
					margin-top: 105px;
					height: calc(100% - 120px);
					overflow: hidden;
					font-family: $font-family-william;
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.03em;
					color: $text-color;
					padding: 0 30px;

					.content-left {
						width: 37%;

						a {
							color: $text-color;
							transition: $color-transition;
						}

						a:hover {
							color: $text-color-dark;
						}

						.title,
						.description {
							width: 90%;
						}

						.title {
							margin-bottom: 25px;
							font-size: 16px;
							color: $text-green;
						}
					}

					.content-right {
						width: 63%;
						overflow: auto;
						padding-right: 10px;

						&::-webkit-scrollbar-track {
							background-color: $scrollbar-color;
						}

						&::-webkit-scrollbar-thumb {
							background-color: $text-color;
							min-height: 40px;
						}

						.list {
							width: 100%;

							.list-item {
								width: calc(100% - 20px);
								display: flex;
								flex-direction: row;
								justify-content: space-between;
								padding-left: 10px;
								padding-right: 10px;
								cursor: pointer;

								.key,
								.value {
									transition: $color-transition;
								}

								&:first-of-type {
									margin-bottom: 25px;
									font-size: 16px;
									color: $text-green;
								}

								&.disabled {
									pointer-events: none;
								}
							}

							@include hover {
								.list-item:hover {
									background: $green;
									.key,
									.value {
										color: $text-color-dark;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.DataSelector > * {
	pointer-events: auto;
}

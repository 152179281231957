@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.LocationDetail {
	position: absolute;
	z-index: $layer-ui-4;

	@include mediaQuery(sml) {
		width: calc(100% - #{$margin-horizontal-mobile} * 2);
	}

	.content {
		background-color: $color-almost-black;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		border: 1px solid $color-almost-black;
		padding: 30px 25px 20px 25px;
		cursor: auto;
		will-change: transform;
		opacity: 0;

		&.curated,
		&.favorites {
				background-color: $color-curated;
				.bottom-arrow{
					border-color: $color-curated;
				}
		}

		&.entertainment {
				background-color: $color-entertainment;
				.bottom-arrow{
					border-color: $color-entertainment;
					background-color:  $color-entertainment;
				}
		}

		&.see {
				background-color: $color-see;
				.bottom-arrow{
					border-color: $color-see;
					background-color:  $color-see;
				}
		}

		&.beauty, &.self-care {
				background-color: $color-beauty;
				.bottom-arrow{
					border-color: $color-beauty;
					background-color:  $color-beauty;
				}
		}

		&.eat {
				background-color: $color-eat;
				.bottom-arrow{
					border-color: $color-eat;
					background-color:  $color-eat;
				}
		}

		&.flex {
				background-color: $color-flex;
				.bottom-arrow{
					border-color: $color-flex;
					background-color:  $color-flex;
				}
		}

		&.learn,
		&.know {
				background-color: $color-learn;	
				.bottom-arrow{
					border-color: $color-learn;	
					background-color:  $color-learn;	
				}		
		}

		&.shop {
				background-color: $color-shop;
				.bottom-arrow{
					border-color: $color-shop;
					background-color:  $color-shop;
				}
		}

		&.stay {
				background-color: $color-stay;
				.bottom-arrow{
					border-color: $color-stay;
					background-color:  $color-stay;
				}
		}

		&.general {
			background-color: $color-general;
			.bottom-arrow{
				border-color: $color-general;
				background-color:  $color-general;
			}
		}
		@include mediaQuery(lrg) {
			width: 390px;
		}

		.bottom-arrow {
			position: absolute;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			bottom: -11px;
			width: 20px;
			height: 20px;
			background-color: $color-almost-black;
			border-right: 1px solid $color-gold;
			border-bottom: 1px solid $color-gold;
		}

		.title {
			font-family: $font-family-martin;
			font-size: 30px;
			line-height: 22px;
			color: $text-color;
			margin-bottom: 20px;
			max-width: 92%;
			text-transform:uppercase;
		}

		&.restaurant {
			// .title {
			// 	font-size: 16px;
			// 	line-height: 16px;
			// 	margin-bottom: 0;
				
			// }
		}

		.rating-reviews {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.poi-rating {
				color: $text-color-dark;
				font-weight: bold;
				margin-right: 10px;
			}

			a.reviews {
				font-family: $font-family-william;
				font-size: 12px;
				text-decoration: underline;
				color: $text-color-dark;
				transition: $color-transition;
				cursor: pointer;

				&.disabled {
					cursor: auto;
					pointer-events: none;
					text-decoration: none;
					padding-top: 2px;
				}
			}

			@include hover {
				a.reviews:hover {
					color: $color-gold;
				}
			}
		}

		.details {
			display: flex;
			justify-content: space-between;

			.text {
				margin-right: 20px;

				.address,
				.phone {
					font-family: $font-family-william;
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.03em;
					color: $text-color-dark;
					&.hm-database {
						padding-right: 30px;
						a {
							color: $text-color-dark;
						}
					}
				}
			}

			.image {
				width: 116px;
				height: 80px;
				background-color: $color-almost-black;
				flex-shrink: 0;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					flex-shrink: 0;
				}
			}

			&.hm-database {
				.image {
					padding-top: 20px;
				}
			}
		}

		.no-flag {
			position: relative;
			width: 1px;
			height: 28px;
			pointer-events: none;
		}

		.flag--wrap {
			position: relative;
			width: 10px;
			height: 12px;
			margin: 14px 0 14px auto;
		}
		.CloseButtonX{
			.line-1,.line-2{
				background-color: $background-color;
			}
		}
		.SaveLocationButton{
			.icon{
				background-color: $background-color;
			}
		}
		.flag {
			width: 10px;
			height: 12px;
			background-color: $background-color;
			-webkit-mask: url(../../../images/icons/flag.svg) no-repeat center;
			mask: url(../../../images/icons/flag.svg) no-repeat center;

			transition: $background-color-transition;
			cursor: pointer;

			&.active {
				background-color: $text-color;
			}
		}

		.tooltip {
			opacity: 0;
		}

		@include hover {
			.flag--wrap:hover {
				.flag {
					background-color: $text-color;
				}
				.tooltip {
					opacity: 1;
				}
			}
		}
	}
}

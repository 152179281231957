@import '../../../../../scss/constants.scss';
@import '../../../../../scss/mixins.scss';

.AboutSubscribe {
	display: block;
	position: relative;
	padding: 30px 40px;
	margin-top: 20px;
	overflow: hidden;

	@include mediaQuery(mobile) {
		padding: 30px 20px 50px;
	}

	.bg {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $gold;
	}

	.title {
		font-family: $font-family-martin;
		font-size: 80px;
		line-height: 56px;
		text-transform: uppercase;
		margin-bottom: 25px;
		@include mediaQuery(mobile) {
			margin-bottom: 0;
		}
	}

	.signup {
		display: flex;
		flex-direction: row;
		align-items: center;

		@include mediaQuery(mobile) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.input-desc {
		max-width: 420px;
		flex: 1;
		margin-right: 10px;
		@include mediaQuery(lrg) {
			p {
				line-height: unset;
			}
		}
	}

	.form-email-wrap {
		flex: 1;
		position: relative;
		@include mediaQuery(mobile) {
			width: 100%;
		}
	}

	.form-email--wrap {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;

		.form-error {
			display: block;
			position: absolute;
			bottom: calc(100% + 10px);
			font-family: $font-family-william;
			font-size: 14px;
			color: $text-color !important;
			a {
				color: $text-color !important;
			}
		}
		input[type='email'] {
			display: block;
			flex: 0 1 auto;
			font-family: $font-family-william;
			border: none;
			height: 30px;
			width: calc(100% - 130px);
			font-size: 16px;
			line-height: 20px;
			letter-spacing: 0.02em;
			padding: 0 15px;
			margin-right: 20px;
		}
		.submit-button {
			border: none;
			display: block;
			width: 16px;
			height: 21px;
			margin-left: 20px;
			cursor: pointer;
			font-size: 0;
			line-height: 0;
			background-color: $background-color;
			-webkit-mask: url(../../../../../images/icons/next-arrow.svg) no-repeat center / 100%;
			mask: url(../../../../../images/icons/next-arrow.svg) no-repeat center / 100%;
			transition: $background-color-transition;
		}

		@include mediaQuery(mobile) {
			> div {
				bottom: unset;
				top: calc(100% + 15px);
				font-size: 10px;
			}
			input[type='email'] {
			}
		}
	}

	.form-email .form-error {
		position: absolute;
		display: block;
		bottom: calc(100% + 5px);
		left: 0;
		color: $text-color;
		> p {
			font-size: 10px;
			margin: 0;
		}
		@include mediaQuery(mobile) {
			bottom: unset;
			top: calc(100% + 5px);
		}
	}

	@include hover {
		.form-email > div {
			.submit-button:hover {
				background-color: $text-color;
			}
		}
	}
}

@import '../../../../../scss/constants.scss';
@import '../../../../../scss/mixins.scss';

.AboutVideo {
  position: relative;
  width: 100%;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    position: relative;
    padding-top: 56.25%;
    width: 100%;
  }
  iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
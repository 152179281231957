@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

$width: 600px;
$header-nav-height: 75px;
$footer-nav-height: 75px;
$header-nav-height-mobile: 50px;
$footer-nav-height-mobile: 50px;
$content-padding-horizontal: 22px;
$content-padding-vertical: 10px;
$scrollbar-padding: 10px;
$top-mobile: 0px;
// $top-mobile: 36px;

.ContentDrawer > * {
	pointer-events: auto;
}

.ContentDrawer {
	position: absolute;
	top: $top-mobile;
	right: 0;
	width: 100%;
	height: calc(100% - #{$top-mobile});
	max-width: 100%;
	z-index: $layer-ui-4;
	overflow: hidden;
	pointer-events: none;

	@include mediaQuery(lrg) {
		top: 0;
		width: $width;
		height: 100%;
		transition: height 0.6s $ease-in-out-circ;
	}

	.drawer-nav-btn {
		position: relative;
		display: flex;
		flex-direction: row;
		height: 100%;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		background: none;
		border: none;
		margin: 0;
		padding: 0 16px;
		font: inherit;
		cursor: pointer;
		color: $color-gold;
		transition: $color-transition;
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			width: 0;
			height: 100%;
			transition: 0.4s width ease-in;
			background-color: $color-gold;
		}
		&:focus {
			outline: none;
		}
		.arrow-btn-label {
			position: relative;
			display: block;
			margin-top: 2px;
			@media only screen and (max-width: 440px) {
				display: none;
			}
		}

		&.show-highlight {
			color: $text-color-dark;
			.arrow-btn {
				background-color: $text-color-dark;
			}
			&::before {
				width: 100%;
				transition: 0.2s width ease-out;
			}
		}

		&--next {
			&::before {
				left: 0;
			}
		}
		&--back {
			&::before {
				right: 0;
			}
		}

		@include hover {
			&:hover {
				color: $text-color;
				.arrow-btn {
					background-color: $text-color;
				}
			}
		}
	}

	&.minimized {
		.content-drawer-container {
			.footer-nav {
				opacity: 0;
				pointer-events: none;
			}

			.content-container {
				overflow-y: hidden;
			}

			.header-nav .item-container .option-container .minimize {
				background-color: $text-color;
			}
		}

		@include mediaQuery(sml) {
			.content-drawer-container {
				transform: translateY(calc(100% - #{$header-nav-height-mobile}));
			}
		}

		@include mediaQuery(lrg) {
			height: $header-nav-height;
		}
	}

	.content-drawer-container {
		@include fill;
		background-color: $background-color;

		@include mediaQuery(sml) {
			transition: transform 0.6s $ease-in-out-circ;
		}

		.header-nav {
			position: -webkit-sticky;
			position: sticky;
			top: 0;
			width: 100%;
			height: $header-nav-height-mobile;

			@include mediaQuery(lrg) {
				width: $width;
				height: $header-nav-height;
			}

			.item-container {
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 $content-padding-horizontal;

				.icon {
					width: 16px;
					height: 10px;
					cursor: pointer;
					background-color: $color-gold;
					transition: $background-color-transition;
				}

				@include hover {
					.icon:hover {
						background-color: $text-color;
					}
				}

				.back-button {
					display: flex;
					align-items: center;
					cursor: pointer;
					flex: 1;

					.back-arrow {
						@extend .icon;
						-webkit-mask: url(../../../images/icons/back-arrow.svg) no-repeat center;
						mask: url(../../../images/icons/back-arrow.svg) no-repeat center;
					}

					.back-label {
						font-family: $font-family-william;
						font-size: 12px;
						line-height: 12px;
						color: $color-gold;
						text-transform: uppercase;
						margin-left: 4px;
						transition: $color-transition;

						@include mediaQuery(lrg) {
							font-size: 14px;
							line-height: 14px;
						}
					}
				}

				@include hover {
					.back-button:hover {
						.back-arrow {
							background-color: $text-color;
						}
						.back-label {
							color: $text-color;
						}
					}
				}

				.option-container {
					flex: 1;
					display: flex;
					justify-content: flex-end;
					align-items: center;

					.back-btn {
						cursor: pointer;
						margin-right: auto;
						display: flex;
						align-items: center;

						.back-btn-icon {
							width: 16px;
							height: 10px;
							background-color: $color-gold;
							-webkit-mask: url(../../../images/icons/back-arrow.svg) no-repeat center;
							mask: url(../../../images/icons/back-arrow.svg) no-repeat center;
							transition: $background-color-transition;
							margin-right: 5px;
						}

						.back-btn-label {
							font-family: $font-family-william;
							font-size: 14px;
							color: $color-gold;
							transition: $color-transition;
							text-transform: uppercase;
						}
					}

					@include hover {
						.back-btn:hover {
							.back-btn-icon {
								background-color: $text-color;
							}

							.back-btn-label {
								color: $text-color;
							}
						}
					}

					.option {
						width: 16px;
						height: 16px;
						cursor: pointer;
						background-color: $color-gold;
						transition: $background-color-transition;
						margin-left: 30px;

						@include mediaQuery(lrg) {
							width: 24px;
							height: 24px;
							margin-left: 24px;
						}
					}

					@include hover {
						.option:hover {
							background-color: $green;
						}
					}

					.share {
						@extend .option;
						-webkit-mask: url(../../../images/icons/share.svg) no-repeat center / 100%;
						mask: url(../../../images/icons/share.svg) no-repeat center / 100%;
					}

					.sound {
						@extend .option;
						-webkit-mask: url(../../../images/icons/sound.svg) no-repeat center / 100%;
						mask: url(../../../images/icons/sound.svg) no-repeat center / 100%;
						width: 18px;
						height: 16px;

						@include mediaQuery(lrg) {
							width: 31px;
							height: 27px;
						}
					}

					.minimize {
						@extend .option;
						background-color: $text-color;
						-webkit-mask: url(../../../images/icons/minimize.svg) no-repeat center / 100%;
						mask: url(../../../images/icons/minimize.svg) no-repeat center / 100%;
					}

					.hamburger {
						@extend .option;
						-webkit-mask: url(../../../images/icons/hamburger.svg) no-repeat center / 100%;
						mask: url(../../../images/icons/hamburger.svg) no-repeat center / 100%;
					}
				}
			}
		}

		.footer-nav {
			left: 0;
			bottom: 0;
			width: 100%;
			height: $footer-nav-height-mobile;
			border-top: 2px solid $text-color;
			background-color: $background-color;
			box-sizing: border-box;
			transition: opacity 0.2s ease-out 0.2s;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: $text-color;
			padding: 0 0 0 12px;

			@include mediaQuery(lrg) {
				position: -webkit-sticky;
				position: sticky;
				height: $footer-nav-height;
			}
			.OptionsFooter {
				a.option {
					color: $color-gold;
					.website-icon-link {
						.link-icon {
							background-color: $color-gold !important;
						}
					}
					.footer-icon {
						background-color: $color-gold !important;
					}
					&:hover {
						color: $text-color !important;
						.website-icon-link {
							.link-icon {
								background-color: $text-color !important;
							}
						}
						.footer-icon {
							background-color: $text-color !important;
						}
					}
				}
			}
			.poi-nav {
				display: flex;
				flex-direction: row;
				height: 100%;
				align-items: center;

				.arrow-btn {
					width: 13px;
					height: 17px;
					cursor: pointer;
					background-color: $color-gold;
					transition: $background-color-transition;

					@include mediaQuery(lrg) {
						width: 16px;
						height: 21px;
					}
				}

				.back-btn {
					@extend .arrow-btn;
					margin-right: 8px;
					-webkit-mask: url(../../../images/icons/previous-arrow.svg) no-repeat center / 100%;
					mask: url(../../../images/icons/previous-arrow.svg) no-repeat center / 100%;
				}

				.next-btn {
					@extend .arrow-btn;
					margin-left: 8px;
					-webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center / 100%;
					mask: url(../../../images/icons/next-arrow.svg) no-repeat center / 100%;
				}
			}

			.index-indicator {
				font-family: $font-family-william;
				font-size: 12px;
				line-height: 17px;
				color: $text-color;
				width: 55px;
				text-align: center;

				@include mediaQuery(lrg) {
					font-size: 14px;
					line-height: 21px;
					width: 65px;
				}
			}
		}

		// city guides
		.main-container {
			// width: 200%; // if people get added back
			width: 100%;
			height: calc(100% - #{$footer-nav-height-mobile} - #{$header-nav-height-mobile}); // footer height
			overflow: hidden;
			flex-wrap: nowrap;
			display: flex;
			transition: transform 0.9s $ease-in-out-circ;
			gap: calc(#{$content-padding-horizontal} * 2);

			@include mediaQuery(lrg) {
				height: calc(100% - #{$footer-nav-height} - #{$header-nav-height}); // footer height
			}

			&.show-people {
				transform: translateX(calc(-50% - 11px));
			}

			.content-container {
				height: 100%;

				.content {
					// width: calc(100% - #{$content-padding-horizontal}); // if people get added back

					&.scrolled {
						width: calc(100% - #{$content-padding-horizontal} - #{$scrollbar-padding});
					}
				}
			}
		}

		// national & city guides & saved locations
		.content-container {
			width: 100%;
			height: calc(100% - #{$footer-nav-height-mobile} - #{$header-nav-height-mobile}); // footer & header height
			min-height: 75px;
			overflow-x: hidden;
			overflow-y: auto;

			@include mediaQuery(lrg) {
				height: calc(100% - #{$footer-nav-height} - #{$header-nav-height}); // footer & header height
			}

			&.no-footer {
				height: calc(100% - #{$header-nav-height-mobile} - #{$top-mobile}); // header height

				@include mediaQuery(lrg) {
					height: calc(100% - #{$header-nav-height}); // header height
				}
			}
		}

		// city guides
		.people-container {
			width: 100%;
			height: 100%;
			min-height: 75px;
			overflow-x: hidden;
			overflow-y: auto;

			.people {
				width: 100%;
				min-height: 1000px;
				background-color: red;
			}
		}

		// national & city guides & saved locations
		.content {
			color: $text-color;
			padding: $content-padding-vertical $content-padding-horizontal 20px $content-padding-horizontal;
			width: calc(100% - #{$content-padding-horizontal} * 2);

			@include mediaQuery(lrg) {
				padding: 0 $content-padding-horizontal 20px $content-padding-horizontal;
			}

			&.scrolled {
				width: calc(100% - #{$scrollbar-padding} - #{$content-padding-horizontal} * 2);
			}

			.social-content {
				width: calc(100% + 20px);
				margin-left: -10px;
			}

			.social-header {
				margin-bottom: 15px;

				.title {
					font-family: $font-family-martin;
					font-size: 32px;
					line-height: 26px;
					color: $color-gold;
					text-transform: uppercase;

					@include mediaQuery(lrg) {
						font-size: 50px;
						line-height: 34px;
					}
				}

				.subtitle {
					font-family: $font-family-martin;
					font-size: 42px;
					line-height: 28px;
					color: $text-color;
					text-transform: uppercase;
					margin-bottom: 18px;

					@include mediaQuery(lrg) {
						font-size: 80px;
						line-height: 56px;
					}
				}

				.options {
					display: flex;

					.option {
						font-family: $font-family-william;
						font-size: 16px;
						line-height: 20px;
						letter-spacing: 0.03em;
						color: $text-color;
						margin-right: 20px;
						cursor: pointer;
						text-transform: uppercase;
						text-decoration: underline;
						text-decoration-color: transparent;
						transition: $color-transition, text-decoration-color 0.2s ease-out;

						&.active {
							color: $color-gold;
							text-decoration-color: $color-gold;
							pointer-events: none;
						}

						&:last-of-type {
							margin-right: unset;
						}
					}

					@include hover {
						.option:hover {
							color: $color-gold;
						}
					}
				}
			}

			.saved-locations {
				.saved-locations-header {
					width: 100%;
					color: $text-color;
					border-bottom: solid 1px $color-gold;
					padding-bottom: 38px;
					margin-bottom: 38px;

					.opt-in-container {
						font-size: 14px;
					}

					&.no-saved {
						border-bottom: none;
					}

					.saved-locations-title {
						font-family: $font-family-martin;
						font-size: 42px;
						line-height: 28px;
						color: $text-color;
						text-transform: uppercase;
						margin-bottom: 20px;
						letter-spacing: auto;

						@include mediaQuery(lrg) {
							font-size: 80px;
							line-height: 56px;
							margin-bottom: 50px;
						}
					}

					form {
						width: 100%;
						margin-top: 20px;

						a {
							color: $text-color;
							transition: $color-transition;
						}

						a:hover {
							color: $color-gold;
						}

						.opt-in-container,
						.email-container {
							display: flex;
							align-items: center;
						}

						input[type='checkbox'] {
							width: 20px;
							height: 20px;
							background-color: $text-color;
							border: none;
							margin: 0 15px 0 0;
							padding: 0;
						}

						input[type='email'] {
							font-family: $font-family-william;
							font-size: 16px;
							line-height: 20px;
							padding: 0 5px;
							width: 300px;
							height: 30px;
							background-color: $text-color;
							border: none;
							margin: 0 20px 0 0;
						}

						input[type='submit'] {
							background-color: transparent;
							border: none;
							font-family: $font-family-william;
							font-size: 18px;
							line-height: 18px;
							letter-spacing: 0.02em;
							color: $color-gold;
							text-transform: uppercase;
						}
					}
				}

				.location-list {
					display: flex;
					flex-direction: column;
					margin-bottom: 30px;
				}
			}

			.image {
				width: 100%;
				height: 150px;
				background-color: lighten($text-color-dark, 15%);
				margin-bottom: 20px;

				@include mediaQuery(lrg) {
					height: 255px;
				}
			}

			.header-container {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;
			}

			.other-section-btn {
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				pointer-events: auto;

				.label {
					font-family: $font-family-william;
					font-size: 12px;
					line-height: 12px;
					letter-spacing: 0.02em;
					color: $color-gold;
					transition: $color-transition;
					text-transform: uppercase;
					margin-right: 4px;

					@include mediaQuery(lrg) {
						font-size: 18px;
						line-height: 18px;
					}
				}

				.arrows {
					width: 16px;
					height: 21px;
					background-color: $color-gold;
					transition: $background-color-transition;
					-webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center;
					mask: url(../../../images/icons/next-arrow.svg) no-repeat center;

					@include mediaQuery(sml) {
						transform: scale(0.8125);
					}
				}
			}

			@include hover {
				.other-section-btn:hover {
					.label {
						color: $text-color;
					}

					.arrows {
						background-color: $text-color;
					}
				}
			}

			.guide-title {
				font-family: $font-family-martin;
				font-size: 32px;
				line-height: 26px;
				color: $color-gold;
				text-transform: uppercase;

				@include mediaQuery(lrg) {
					font-size: 50px;
					line-height: 34px;
				}
			}

			.guide-location {
				margin-top: 6px;
				margin-bottom: 10px;
				font-family: $font-family-william;
				font-size: 14px;
				line-height: 14px;
				color: #a1a0a2;
			}

			.poi-title {
				font-family: $font-family-martin;
				font-size: 42px;
				line-height: 28px;
				color: $text-color;
				text-transform: uppercase;
				margin-bottom: 20px;

				@include mediaQuery(lrg) {
					font-size: 80px;
					line-height: 56px;
				}
			}

			.poi-location {
				font-family: $font-family-william;
				font-size: 18px;
				line-height: 20px;
				color: $text-color;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-right: 5px;

				.SaveLocationButton.noBg {
					width: 54px;
					height: 54px;
					right: 0;
					.icon {
						width: 54px;
						height: 54px;
					}
				}

				@include mediaQuery(sml) {
					margin-bottom: 15px;
				}

				@include mediaQuery(lrg) {
					font-size: 28px;
					line-height: 30px;
				}
			}

			.poi-desc {
				color: $text-color;
			}

			.city-state {
				font-family: $font-family-william;
				font-size: 14px;
				line-height: 14px;
				color: #a1a0a2;
			}

			.other-guides {
				margin-top: 30px;

				&.with-border {
					border-top: 1px solid $color-gold;
					padding-top: 30px;
				}

				.other-guides-title {
					font-family: $font-family-martin;
					font-size: 42px;
					line-height: 28px;

					color: $text-color;
					text-transform: uppercase;
					margin-bottom: 20px;

					@include mediaQuery(lrg) {
						font-size: 50px;
						line-height: 34px;
					}
				}

				.other-guides-items {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;

					@include mediaQuery(lrg) {
						flex-direction: row;
					}

					.other-guide-item {
						display: flex;
						cursor: pointer;
						margin-right: 46px;
						&:last-of-type {
							margin-right: 0;
						}

						@include mediaQuery(sml) {
							margin-right: unset;
							margin-bottom: 46px;

							&:last-of-type {
								margin-right: unset;
								margin-bottom: 0;
							}
						}

						@include mediaQuery(lrg) {
							flex-direction: column;
							width: 154px;
						}

						.image {
							width: 136px;
							height: 75px;
							margin-bottom: 0px;
							transition: filter 0.2s ease-out;
							flex-shrink: 0;

							@include mediaQuery(sml) {
								margin-right: 15px;
							}

							@include mediaQuery(lrg) {
								width: 100%;
								height: 86px;
								margin-bottom: 5px;
							}
						}

						.other-guide-item-content {
							@include mediaQuery(sml) {
								width: 50%;
								display: flex;
								flex-direction: column;
								justify-content: space-between;
							}

							.other-guide-item-desc {
								font-family: $font-family-william;
								font-size: 14px;
								line-height: 16px;
								letter-spacing: 0.03em;
								color: $color-gold;
								transition: $color-transition;

								@include mediaQuery(lrg) {
									font-size: 16px;
									line-height: 20px;
								}
							}

							.other-guide-item-title {
								font-family: $font-family-william;
								font-size: 14px;
								line-height: 12px;
								letter-spacing: 0.03em;
								text-transform: uppercase;
								color: $text-color;
								transition: $color-transition;

								@include mediaQuery(lrg) {
									font-size: 16px;
									line-height: 20px;
								}
							}
						}
					}

					&.full {
						justify-content: space-between;

						.other-guide-item {
							color: red;
							@include mediaQuery(sml) {
								margin-bottom: 15px;
								&:last-of-type {
									margin-bottom: 0;
								}
							}
						}
					}

					@include hover {
						.other-guide-item:hover {
							.image {
								filter: grayscale(65%);
							}

							.other-guide-item-desc {
								color: $text-color;
							}

							.other-guide-item-title {
								color: $color-gold;
							}
						}
					}
				}
			}

			.more-in-this-area {
				margin-top: 30px;
				display: flex;
				flex-direction: column;

				.more-in-this-area-title {
					font-family: $font-family-martin;
					font-size: 42px;
					line-height: 28px;
					color: $text-color;
					text-transform: uppercase;
					margin-bottom: 20px;

					@include mediaQuery(lrg) {
						font-size: 50px;
						line-height: 34px;
					}
				}

				.more-in-this-area-items {
					display: flex;
					flex-direction: column;
					margin-bottom: 30px;
				}

				.more-in-this-area-load-more-btn {
					display: flex;
					align-items: center;
					align-self: center;
					cursor: pointer;

					.label {
						font-family: $font-family-william;
						font-size: 12px;
						line-height: 12px;
						color: $text-color;
						transition: $color-transition;
						margin: 0 9px;

						@include mediaQuery(lrg) {
							font-size: 20px;
							line-height: 22px;
						}
					}

					.down-arrows {
						width: 21px;
						height: 17px;
						background-color: $color-gold;
						transition: $background-color-transition;
						-webkit-mask: url(../../../images/icons/down-arrow.svg) no-repeat center;
						mask: url(../../../images/icons/down-arrow.svg) no-repeat center;

						@include mediaQuery(sml) {
							transform: scale(0.8125);
						}
					}
				}

				@include hover {
					.more-in-this-area-load-more-btn:hover {
						.label {
							color: $color-gold;
						}
					}
				}
			}

			.media-embed {
				margin-top: 30px;
			}

			.content-footer {
				margin-top: 30px;
				padding: 38px 0 0 0;
				border-top: 1px solid $color-gold;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				@include mediaQuery(lrg) {
					padding: 38px 0;
				}

				.content-footer-option {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: 22px;
					cursor: pointer;

					.content-footer-option-label {
						font-family: $font-family-william;
						font-size: 12px;
						line-height: 12px;
						letter-spacing: 0.02em;
						color: $color-gold;
						transition: $color-transition;
						text-transform: uppercase;
						margin-right: 4px;

						@include mediaQuery(lrg) {
							font-size: 18px;
							line-height: 18px;
						}
					}

					.content-footer-option-arrows {
						width: 16px;
						height: 21px;
						background-color: $color-gold;
						transition: $background-color-transition;
						-webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center;
						mask: url(../../../images/icons/next-arrow.svg) no-repeat center;

						@include mediaQuery(sml) {
							transform: scale(0.8125);
						}
					}
				}

				@include hover {
					.content-footer-option:hover {
						.content-footer-option-label {
							color: $text-color;
						}

						.content-footer-option-arrows {
							background-color: $text-color;
						}
					}
				}
			}
		}

		.standard-city-no-stats {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.body-content {
				font-family: $font-family-william;
				font-size: 12px;
				line-height: 12px;
				color: $text-color;
				text-align: center;
				white-space: pre-line;
				margin: 25px 0;

				@include mediaQuery(lrg) {
					font-size: 18px;
					line-height: 22px;
				}
			}

			.content-footer-option {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 22px;
				cursor: pointer;

				.content-footer-option-label {
					font-family: $font-family-william;
					font-size: 12px;
					line-height: 12px;
					letter-spacing: 0.02em;
					color: $color-gold;
					transition: $color-transition;
					text-transform: uppercase;
					margin-right: 4px;

					@include mediaQuery(lrg) {
						font-size: 18px;
						line-height: 18px;
					}
				}

				.content-footer-option-arrows {
					width: 16px;
					height: 21px;
					background-color: $color-gold;
					transition: $background-color-transition;
					-webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center;
					mask: url(../../../images/icons/next-arrow.svg) no-repeat center;

					@include mediaQuery(sml) {
						transform: scale(0.8125);
					}
				}
			}

			@include hover {
				.content-footer-option:hover {
					.content-footer-option-label {
						color: $text-color;
					}

					.content-footer-option-arrows {
						background-color: $text-color;
					}
				}
			}
		}

		.no-saved-locations-message {
			position: relative;
			display: block;
			width: 100%;
			.no-saved--wrap {
				display: flex;
				height: 100%;
				width: 100%;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				max-width: 407px;
				margin: 0 auto;
				text-align: center;
				font-family: $font-family-william;
				font-size: 18px;
				line-height: 22px;
				letter-spacing: 0.03em;
				.icon {
					width: 72px;
					height: 72px;
					background-color: $color-gold;
					-webkit-mask: url(../../../images/icons/white/favourite.svg) no-repeat center;
					mask: url(../../../images/icons/white/favourite.svg) no-repeat center;
					transition: $background-color-transition;
					margin-bottom: 10px;
				}
				.title {
					font-weight: bold;
					margin-bottom: 15px;
				}

				.content-footer-option {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: 22px;
					cursor: pointer;

					.content-footer-option-label {
						font-family: $font-family-william;
						font-size: 12px;
						line-height: 12px;
						letter-spacing: 0.02em;
						color: $color-gold;
						transition: $color-transition;
						text-transform: uppercase;
						margin-right: 4px;

						@include mediaQuery(lrg) {
							font-size: 18px;
							line-height: 18px;
						}
					}

					.content-footer-option-arrows {
						width: 16px;
						height: 21px;
						background-color: $color-gold;
						transition: $background-color-transition;
						-webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center;
						mask: url(../../../images/icons/next-arrow.svg) no-repeat center;

						@include mediaQuery(sml) {
							transform: scale(0.8125);
						}
					}
				}

				@include hover {
					.content-footer-option:hover {
						.content-footer-option-label {
							color: $text-color;
						}

						.content-footer-option-arrows {
							background-color: $text-color;
						}
					}
				}
			}
		}
	}
}

@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

.map-container {
	@include fill;
	z-index: $layer-base-1;

	&.cluster {
		.cluster-marker {
			display: none;
		}
	}

	&.active {
		.location-marker.active {
			display: none;
		}
	}

	// filters
	&.curated {
		.location-marker.curated {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}

	&.saved {
		.location-marker.saved {
			&:not(.active) {
				display: none;
			}
		}
	}

	&.entertainment {
		.location-marker.entertainment {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}

	&.see {
		.location-marker.see {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}

	&.beauty,
	&.self-care {
		.location-marker.beauty,
		.location-marker.self-care {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}

	&.shop {
		.location-marker.shop {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}

	&.stay {
		.location-marker.stay {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}

	&.learn,
	&.know {
		.location-marker.learn,
		.location-marker.know {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}

	&.flex {
		.location-marker.flex {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}

	&.eat {
		.location-marker.eat {
			&:not(.saved):not(.active) {
				display: none;
			}
		}
	}
}

.map-input-blocker {
	@include fill;
	z-index: $layer-max;
}

.heightmap-container {
	position: absolute;
	z-index: $layer-ui-1;
	top: 0;
	left: 0;
	width: 512px;
}

.heightmap {
	width: 512px;
	height: 256px;
}

.marker-label {
	color: $text-color;
	font-family: $font-family-william;
	font-size: 11px;
	text-align: center;
	max-width: 300px;
	pointer-events: none;
	transition: opacity 0.3s ease-out;
	opacity: 0;
}

.marker {
	transition: opacity 0.3s ease-out;
	will-change: transform, opacity;
	opacity: 0;
}

.cluster-marker {
	@extend .marker;
	position: absolute;
	width: $cluster-icon-base-size;
	height: $cluster-icon-base-size;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	.cluster-marker-bg {
		@include fill;
		background-color: $color-gold;
		transition: transform 0.35s $ease-out-back, opacity 0.35s ease-out;
		transform: rotate(45deg);
		opacity: 0;
	}

	.cluster-marker-icon {
		@include fill;
		transform-origin: center;
		transition: transform 0.3s $ease-out-back;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.cluster-marker-outer-icon {
		@include fill;
		transform-origin: center;
		transition: transform 0.3s $ease-out-back, opacity 0.2s ease-out;

		img {
			width: 100%;
			height: 100%;
		}
	}
}

.cluster-marker:hover {
	z-index: $layer-ui-3 !important;

	.cluster-marker-bg {
		transform: scale(1.5) rotate(135deg);
		background-color: $color-gold;
		opacity: 1;
	}

	.cluster-marker-icon {
		transform: scale(1.25);
	}

	.cluster-marker-outer-icon {
		transform: scale(1.5) rotate(90deg);
		opacity: 0;
	}
}

.location-marker {
	@extend .marker;
	position: absolute;
	width: $icon-base-size;
	height: $icon-base-size;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&.hidden {
		pointer-events: none;
	}

	// icon colors
	&.curated {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-curated;
		}
	}

	&.entertainment {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-entertainment;
		}
	}

	&.see {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-see;
		}
	}

	&.beauty,
	&.self-care {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-beauty;
		}
	}

	&.shop {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-shop;
		}
	}

	&.stay {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-stay;
		}
	}

	&.learn,
	&.know {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-learn;
		}
	}

	&.eat {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-eat;
		}
	}

	&.flex {
		.location-marker-icon-container .location-marker-bg {
			background-color: $color-flex;
		}
	}

	.location-marker-icon-container {
		@include fill;
		pointer-events: none; // fixes marker hovers triggering multiple times
		display: flex;
		align-items: center;
		justify-content: center;

		.location-marker-bg {
			position: absolute;
			width: calc(100% - 5px);
			height: calc(100% - 5px);
			background-color: #af997e;
			transition: transform 0.35s $ease-out-back, background-color 0.35s ease-out;
			transform: rotate(45deg);
		}

		.location-marker-icon {
			@include fill;
			transform-origin: center;
			transition: transform 0.3s $ease-out-back;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.location-marker-label {
		@include center-x;
		top: calc(100% + 26px);
		text-align: center;
		font-family: $font-family-william;
		font-size: 20px;
		line-height: 22px;
		color: $text-color;
		width: 200px;
		transition: opacity 0.3s ease-out;
		opacity: 0;
		pointer-events: none;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
		text-transform: capitalize;
	}

	// active/selected state
	&.active {
		pointer-events: none;
		z-index: $layer-ui-3 !important;

		.location-marker-icon {
			transform: scale(1.25);
		}

		.location-marker-bg {
			transform: scale(1.8) rotate(135deg);
			// background-color: #ffffff !important;
		}

		.location-marker-label {
			opacity: 0;
		}
	}

	&.highlighted {
		z-index: $layer-ui-3 !important;

		.location-marker-icon {
			transform: scale(1.25);
		}

		.location-marker-bg {
			transform: scale(1.8) rotate(135deg);
			background-color: #c2927e;
		}

		.location-marker-label {
			opacity: 1;
		}
	}

	&.filtered {
		display: none;
	}

	&.forced-hover {
		z-index: $layer-ui-3 !important;
		pointer-events: auto;

		.location-marker-bg {
			transform: scale(1.8) rotate(135deg);
		}

		.location-marker-icon {
			transform: scale(1.25);
		}

		.location-marker-label {
			opacity: 1;
		}
	}
}

@include hover {
	.location-marker:hover {
		z-index: $layer-ui-3 !important;

		.location-marker-bg {
			transform: scale(1.8) rotate(135deg);
		}

		.location-marker-icon {
			transform: scale(1.25);
		}

		.location-marker-label {
			opacity: 1;
		}

		// &.forced-hover {
		// 	.location-marker-bg {
		// 		background-color: $text-color;
		// 	}
		// }
	}
}

.center-bounds {
	@include center;
	z-index: $layer-ui-2;
	border: solid 1px white;
	pointer-events: none;
}

.search-container {
	position: absolute;
	top: $margin-vertical-mobile;
	width: 100%;
	top: 0;
	left: 0;

	@include mediaQuery(sml) {
		right: $margin-horizontal-mobile;
	}

	@include mediaQuery(lrg) {
		width: 100%;
		top: 0;
		left: 0;
	}

	// on desktop only, mobile is located in Nav.scss
	.search {
		@include center-x;
		top: calc(#{$margin-vertical} + 10px);
		font-family: $font-family-william;
		font-size: 14px;
		line-height: 20px;
		text-transform: uppercase;
		border-bottom: 1px solid var(--nav-color-tc);
		color: var(--nav-color-tc);
		padding-bottom: 12px;
		// padding: 12px 0;
		max-width: 100%;
		width: 440px;
		z-index: $layer-ui-1;
		cursor: text;
		transition: $color-transition, border-bottom 0.2s ease-out;

		@include mediaQuery(smallDesktop) {
			width: 340px;
		}

		@include mediaQuery(tablet) {
			width: 250px;
		}

		@include mediaQuery(sml) {
			width: 180px;
			top: 40px;
			left: calc(50% + 75px);
		}

		&::after {
			content: '';
			width: 24px;
			background-color: var(--nav-color-tc);
			margin-bottom: 22px;
			height: 24px;
			position: absolute;
			right: 0;
			-webkit-mask: url(../../../images/icons/search.svg) no-repeat center;
			mask: url(../../../images/icons/search.svg) no-repeat center;
			transition: $background-color-transition;
		}
	}
}

@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.CookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 36px 60px;
  background-color: #252325;
  z-index: $layer-consent;
  box-sizing: border-box;
  .content-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .consent-info {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-right: 50px;
    max-width: 708px;
    color: $text-color;
    a { 
      color: $text-color;
    }
  }

  .button-accept {
    border: none;
    display: block;
    cursor: pointer;
    font-size: 26px;
    line-height: 26px;
    font-family: $font-family-william;
    color: $text-color;
    background-color: $color-gold;
    padding: 12px 29px 11px;
    transition: $color-transition;
  }

  @include mediaQuery(mobile) {
    padding: 16px 30px;
    .content-wrap {
      flex-direction: column;
    }
    .consent-info {
      margin-right: 0;
      max-width: 100%;
    }
    .consent-cta {
      width: 100%;
    }
    .button-accept {
      display: block;
      width: 100%;
    }
  }

  @include hover {
    .button-accept:hover {
      color: $background-color;
    }
  }
}
@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.OptionsFooter {
	display: flex;

	.website-icon-link {
		display: flex;	
		flex-direction: row;
		align-items: center;
		height: 100%;
		min-width: 20px;
		white-space: nowrap;
		.link-icon {
			background-color: $text-color-dark;
			transition: $background-color-transition;
			&.yelp {
				height: 18px;
				width: 43px;
				-webkit-mask: url(../../../images/icons/logo-yelp.svg) no-repeat center;
				mask: url(../../../images/icons/logo-yelp.svg) no-repeat center;
			}
			&.google {
				height: 17px;
				width: 51px;
				-webkit-mask: url(../../../images/icons/logo-google.svg) no-repeat center;
				mask: url(../../../images/icons/logo-google.svg) no-repeat center;
			}
		}
	}

	&.is-popover {
		padding-top: 20px;
		border-top: 1px solid $background-color;
	}

	&.dark-theme {
		a.option {
			color: $text-color-dark;
			.website-icon-link {
				.link-icon {
					background-color: $text-color-dark;
				}
			}
			.icon {
				background-color: $text-color-dark;
			}
		}
	}

	a.option {
		position: relative;
		font-family: $font-family-william;
		font-size: 12px;
		color: $background-color;
		text-transform: uppercase;
		text-decoration: none;
		margin-right: 24px;
		transition: $color-transition;
		cursor: pointer;
		display: flex;
		align-items: center;
		.website-icon-link {
			.link-icon {
				background-color: $background-color;
			}
		}
		.icon {
			background-color: $background-color;
			transition: $background-color-transition;
		}
		.font-italic{
			font-style: italic;
			text-transform: none;
			font-family: 'Times New Roman', Times, serif;
			font-size: 20px;
		}
		.website {
			@extend .icon;
			width: 23px;
			height: 22px;
			margin-top: -4px;
			-webkit-mask: url(../../../images/icons/external-link.svg) no-repeat center;
			mask: url(../../../images/icons/external-link.svg) no-repeat center;
		}

		.directions {
			@extend .icon;
			width: 23px;
			height: 22px;
			margin-top: -2px;
			-webkit-mask: url(../../../images/icons/map.svg) no-repeat center;
			mask: url(../../../images/icons/map.svg) no-repeat center;
		}

		.share {
			@extend .icon;
			width: 18px;
			height: 10px;
			margin-right: 6px;
			-webkit-mask: url(../../../images/icons/share-alt.svg) no-repeat center;
			mask: url(../../../images/icons/share-alt.svg) no-repeat center;
		}
	}

	@include hover {
		
		a.option:hover {
			color: $text-color !important;
			.website-icon-link {
				.link-icon {
					background-color: $text-color;
				}
			}
			.icon {
				background-color: $text-color;
			}
		}
	}

	@media only screen and (max-width: 550px) {
		.footer-icon {
			display: none;
		}
	}

	@media only screen and (max-width: 490px) {
		.footer-icon {
			display: block;
		}
		.footer-label {
			display: none;
		}
	}
}

@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

.MapWrapper {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}

.debug-info {
	position: absolute;
	top: 0;
	right: 0;
	color: $background-color;
	background-color: $text-color;
	padding: 5px;
	font-family: monospace;
	pointer-events: none;
}

.vignette {
	@include fill;
	background: radial-gradient(circle, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 100%);
	pointer-events: none;
	opacity: 0.45;
	z-index: $layer-base-2;
}

.load-error {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;

	@include mediaQuery(lrg) {
		cursor: pointer;
	}

	.message {
		max-width: 60%;
		text-align: center;
		color: $text-color;
		transition: $color-transition;
		padding: $margin-vertical-mobile $margin-horizontal-mobile;
		z-index: 9999;

		@include mediaQuery(lrg) {
			max-width: 300px;
			padding: $margin-vertical $margin-horizontal;
		}
	}
}

@include hover {
	.load-error:hover {
		.message {
			color: $color-gold;
		}
	}
}

@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

.MapLegend {
	display: block;
	position: absolute;
	left: 0;
	top: 50%;
	z-index: $layer-ui-2;

	.legend-head-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		padding-bottom: 6px;
		padding-right: 24px;
	}

	.toggle-all {
		position: relative;
		width: 26px;
		height: 26px;
		overflow: hidden;
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 40px;
			height: 40px;
			transform: translate(-50%, -50%);
			background-color: $text-color;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			-webkit-mask: url(../../../images/icons/white/see.svg) no-repeat center / 100%;
			mask: url(../../../images/icons/white/see.svg) no-repeat center / 100%;
			transition: $background-color-transition;
		}

		&--off {
			&::after {
				content: '';
				display: block;
				width: 24px;
				height: 1px;
				background-color: $text-color;
				position: absolute;
				top: 50%;
				left: 50%;
				transform-origin: center;
				transform: translate(-50%, -50%) rotate(45deg);
				transition: $background-color-transition;
			}
		}
		@include hover {
			&:hover {
				&::before {
					background-color: $color-gold;
				}
				&::after {
					background-color: $color-gold;
				}
			}
		}
	}

	.legend-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		white-space: nowrap;
		.arrow {
			display: inline-block;
			width: 16px;
			height: 21px;
			background-color: var(--nav-color-cl);
			transition: $background-color-transition;
		}
		.label {
			color: var(--nav-color-cl);
			display: inline-block;
			font-family: $font-family-william;
			font-size: 14px;
			line-height: 21px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			transition: $color-transition;
		}
		@include hover {
			&:hover {
				.arrow {
					background-color: $text-color;
				}
				.label {
					color: $text-color;
				}
			}
		}
	}

	.legend-open {
		position: absolute;
		left: 10px;
		top: 0;
		transform-origin: top left;
		transform: rotate(-90deg) translate(-50%, 0%);
		transition: 0.5s opacity ease-out, 0.5s transform ease-out;
		transition-delay: 1s;
		.arrow {
			transform: rotate(90deg);
			-webkit-mask: url(../../../images/icons/next-arrow.svg) no-repeat center / 100%;
			mask: url(../../../images/icons/next-arrow.svg) no-repeat center / 100%;
		}
		.label {
			margin-right: 15px;
		}
	}

	.legend-close {
		position: relative;
		padding-left: 10px;
		.arrow {
			background-color: $text-color;
			-webkit-mask: url(../../../images/icons/previous-arrow.svg) no-repeat center / 100%;
			mask: url(../../../images/icons/previous-arrow.svg) no-repeat center / 100%;
		}
		.label {
			margin-left: 15px;
			color: $text-color;
		}
		.sep {
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 0;
			border-bottom: 1px solid $text-color;
		}
	}

	.legend-tray {
		color: $text-color;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		transform: translateY(-50%);
		font-family: $font-family-william;
		font-size: 14px;
		line-height: 15px;
		letter-spacing: 0.03em;
		padding: 20px 20px 20px 0;
	}

	.legend-list {
		margin-top: 10px;
		padding-left: 40px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 10px 20px;
	}

	.legend-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
		transition: $opacity-transition;
		user-select: none;

		&.filtered {
			// opacity: 0.2 !important;

			.icon {
				.icon-white {
					opacity: 1;
				}
				.icon-black {
					opacity: 0;
				}
			}

			.icon-bg {
				background-color: transparent !important;
				border: 1px solid $text-color;
			}
		}

		.icon {
			position: relative;
			display: block;
			width: 26px;
			height: 26px;

			.icon-white {
				opacity: 0;
			}

			> img {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				transition: opacity 0.35s ease-out;
			}
		}

		.icon-bg {
			display: block;
			position: absolute;
			width: calc(100% - 5px);
			height: calc(100% - 5px);
			top: 50%;
			left: 50%;
			background-color: #af997e;
			transform: translate(-50%, -50%) rotate(45deg);
			transition: transform 0.35s $ease-out-back, background-color 0.35s ease-out, border 0.35s ease-out;
		}

		.label {
			display: block;
			margin-left: 15px;
			flex-shrink: 0;
			transition: $color-transition;
			user-select: none;
			white-space: nowrap;
			overflow: hidden;
			width: 0;
		}

		&.toggle-all {
			.label {
				padding: 2px 0;
			}
		}

		&.curated,
		&.favorites {
			.icon-bg {
				background-color: $color-curated;
			}
		}

		&.entertainment,
		&.entertain {
			.icon-bg {
				background-color: $color-entertainment;
			}
		}

		&.see {
			.icon-bg {
				background-color: $color-see;
			}
		}

		&.beauty {
			.icon-bg {
				background-color: $color-beauty;
			}
		}

		&.eat {
			.icon-bg {
				background-color: $color-eat;
			}
		}

		&.flex {
			.icon-bg {
				background-color: $color-flex;
			}
		}

		&.learn {
			.icon-bg {
				background-color: $color-learn;
			}
		}

		&.shop {
			.icon-bg {
				background-color: $color-shop;
			}
		}

		&.stay {
			.icon-bg {
				background-color: $color-stay;
			}
		}

		&.general {
			pointer-events: none;

			.icon-bg {
				background-color: $color-general;
			}
		}
	}

	@include hover {
		.legend-item:hover {
			// opacity: 1 !important;
			.label {
				color: $color-gold;
			}
		}
	}

	.legend-mobile-bg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(32, 31, 33, 0.9);
	}

	&[open] {
		.legend-open {
			pointer-events: none;
			opacity: 0;
			transform: rotate(-90deg) translate(-50%, 10px);
			transition: 0.1s opacity ease-out, 0.1s transform ease-out;
			transition-delay: 0s;
		}
	}

	@media screen and (max-height: 700px) {
		.legend-tray {
			top: 20px;
		}
		.legend-list {
			grid-template-columns: 1fr 1fr;
		}
	}

	@media screen and (max-height: 480px) {
		.legend-tray {
			top: 20px;
		}
		.legend-list {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	@include mediaQuery(mobile) {
		.legend-head-wrap {
			width: auto;
		}

		.legend-tray {
			width: 100vw;
		}

		.legend-item .label,
		.legend-close .label {
			color: $text-color;
		}
	}

	button {
		background: none;
		color: inherit;
		border: none;
		margin: 0;
		padding: 0;
		font: inherit;
		cursor: pointer;
		&:focus {
			outline: none;
		}
	}
}

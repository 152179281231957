@import '../scss/constants.scss';
@import '../scss/mixins.scss';

:root {
	--background-color: #af997e;

	// top left
	--nav-color-tl: #{$text-color-dark};
	--nav-color-tl-hover: #{$green};

	--nav-color-bl-alt: #{$text-color-dark};
	--nav-color-bl-alt-hover: #{$green};

	// bottom left
	--nav-color-bl: #{$text-color-dark};
	--nav-color-bl-hover: #{$green};

	// top right
	--nav-color-tr: #{$text-color-dark};
	--nav-color-tr-hover: #{$green};

	// bottom right
	--nav-color-br: #{$text-color-dark};
	--nav-color-br-hover: #{$green};
	// --nav-color-br-highlighted: #{$text-color};

	// top center
	--nav-color-tc: #{$text-color-dark};
	--nav-color-tc-hover: #{$green};

	// top center alt (lower elements)
	--nav-color-tc-alt: #{$text-color-dark};
	--nav-color-tc-alt-hover: #{$green};

	// bottom center
	--nav-color-bc: #{$text-color-dark};
	--nav-color-bc-hover: #{$green};

	--nav-color-tl: #fff !important;
	--nav-color-bl: #fff !important;
	--nav-color-bl-alt: #fff !important;
	--nav-color-tr: #fff !important;
	--nav-color-br: #fff !important;
	--nav-color-tc: #fff !important;
	--nav-color-bc: #fff !important;
	--nav-color-cl: #fff !important;
}

html,
body {
	touch-action: none; // disable page scaling/zooming
}

body {
	margin: 0;
	font-family: $font-family-william;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: var(--background-color);
}

p {
	font-family: $font-family-william;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.375;
	letter-spacing: 0.02em;
}

a {
	transition: $color-transition !important;
}

a:hover {
	color: $green !important;
}

#root {
	@include fill;
	position: fixed;
}

*:focus {
	outline: none;
}

*::-webkit-scrollbar {
	width: 8px;
}

*::-webkit-scrollbar-track {
	background-color: white;
}

*::-webkit-scrollbar-thumb {
	background-color: $scrollbar-color;
	min-height: 40px;
}

@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

$padding-h: 75px;
$padding-v: 75px;

.AddToMap {
	.AddToMap-copy {
		width: 100%;

		&.no-scroll {
			overflow-y: hidden;
		}

		.sub-header {
			font-size: 26px;
		}

		@include mediaQuery(lrg) {
			max-width: 550px;
			height: unset;
		}
	}
}

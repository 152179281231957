@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.AmbassadorLink {
  font-family: $font-family-william;
  font-size: 12px;
  line-height: 16px;
  color: $color-gold;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;

  @include mediaQuery(lrg) {
    font-size: 14px;
    line-height: 14px;
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
  }

  a {
    font-family: $font-family-william;
    font-size: 12px;
    line-height: 16px;
    color: $color-gold;
    text-decoration: underline;
    transition: $color-transition;

    @include mediaQuery(lrg) {
      font-size: 14px;
      line-height: 14px;
    }
  }

  @include hover {
    a:hover {
      color: $text-color;
    }
  }
}
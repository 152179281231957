@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.CityLanding {
	@include fill;
	z-index: $layer-ui-4;

	@include mediaQuery(sml) {
		.CityStats {
			margin-bottom: 0;
		}
	}

	.image-mask-container {
		@include fill;
		position: relative;
		overflow: hidden;
		height: 0%;

		.image-container {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: $text-color-dark;

			img {
				width: 100vw;
				height: 100vh;
				object-fit: cover;
				filter: brightness(0.4);
				transition: $opacity-transition;
			}

			video {
				width: 100vw;
				height: 100vh;
				filter: brightness(0.6);
			}
		}
	}

	.content-container {
		@include fill;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-family: $font-family-martin;

		.location-name {
			font-size: 64px;
			line-height: 90px;
			color: $text-green;
			text-transform: uppercase;

			@include mediaQuery(lrg) {
				font-size: 230px;
				line-height: 210px;
				margin-bottom: 20px;
			}

			@include mediaQuery(tablet) {
				font-size: 130px;
				line-height: 130px;
			}

			@include mediaQuery(largeTablet) {
				font-size: 130px;
				line-height: 130px;
			}
		}

		.header {
			font-family: $font-family-william;
			font-size: 16px;
			line-height: 16px;
			color: $color-gold;
			text-transform: uppercase;
			margin-bottom: 12px;

			@include mediaQuery(lrg) {
				font-size: 20px;
				line-height: 22px;
			}
		}

		.title {
			font-size: 42px;
			line-height: 28px;
			color: $text-color;
			text-transform: uppercase;
			max-width: 80%;
			text-align: center;
			margin-bottom: 16px;
			padding: 0 20px;

			@include mediaQuery(lrg) {
				font-size: 80px;
				line-height: 56px;
				max-width: 600px;
			}
		}

		.start-btn {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 44px;
			pointer-events: auto;

			.label {
				font-size: 42px;
				line-height: 42px;
				color: $color-gold;
				text-transform: uppercase;
				margin-right: 7px;
				transition: $color-transition;

				@include mediaQuery(lrg) {
					font-size: 80px;
					line-height: 56px;
				}
			}

			svg {
				margin-top: 3px;

				@include mediaQuery(lrg) {
					margin-top: 5px;
				}

				path {
					fill: $color-gold;
					transition: fill 0.2s ease-out;
				}
			}
		}

		@include hover {
			.start-btn:hover {
				div {
					color: $text-color;
				}

				svg {
					path {
						fill: $text-color;
					}
				}
			}
		}
	}
}

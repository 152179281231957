@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

$line-width: 32px;
$line-width-small: 21px;
$size: calc(#{$line-width} * 0.75);

.CloseButtonX {
	position: absolute;
	top: 40px;
	right: 40px;
	cursor: pointer;
	width: $size;
	height: $size;

	.line {
		position: absolute;
		left: 50%;
		top: 50%;
		width: $line-width;
		height: 2px;
		background-color: $text-color;
		transform-origin: center;
		transition: $background-color-transition;

		&.small {
			width: $line-width-small;
		}

		&.theme-dark {
			background-color: $text-color;
		}

		&.theme-light {
			background-color: $text-color;
		}
	}

	.line-1 {
		@extend .line;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	.line-2 {
		@extend .line;
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}

@include hover {
	.CloseButtonX:hover {
		.line-1,
		.line-2 {
			background-color: $green;

			&.theme-light {
				background-color: $color-gold;
			}
		}
	}
}

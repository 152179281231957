@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.footer {
	font-family: $font-family-william;
	font-size: 14px;
	line-height: 16px;
	color: $text-color;
	letter-spacing: 0.03em;
	padding: 20px 0 25px;
	display: flex;
	align-items: center;
	@include mediaQuery(lrg) {
		padding: 20px 0 $margin-vertical;
	}
	@media screen and (max-height: 850px) {
		padding-bottom: 30px;
	}
	.copyright {
		margin-right: 20px;
		display: none;
		@include mediaQuery(lrg) {
			display: block;
		}
	}

	.link {
		margin-right: 20px;
		cursor: pointer;
		transition: $color-transition;
	}

	.contact-link {
		text-decoration: none;
		color: $text-color;
	}

	@include hover {
		.link:hover {
			color: $color-gold;
		}
	}

	.twitter-link {
		width: 19px;
		height: 15px;
		margin-right: 20px;
		-webkit-mask: url(../../../images/icons/twitter.svg) no-repeat center;
		mask: url(../../../images/icons/twitter.svg) no-repeat center;
		background-color: $text-color;
		cursor: pointer;
		transition: $background-color-transition;
	}

	.instagram-link {
		width: 16px;
		height: 16px;
		-webkit-mask: url(../../../images/icons/instagram.svg) no-repeat center;
		mask: url(../../../images/icons/instagram.svg) no-repeat center;
		background-color: $text-color;
		cursor: pointer;
		transition: $background-color-transition;
	}

	@include hover {
		.twitter-link:hover,
		.instagram-link:hover {
			background-color: $color-gold;
		}
	}
}
.AddToMap,
.HamburgerMenu,
.CityGuides {
	.footer-container {
		position: absolute;
		bottom: 0;
		right: $margin-horizontal-mobile;

		@include mediaQuery(lrg) {
			right: $margin-horizontal;
		}

		.footer {
			justify-content: flex-end;
		}
	}
}

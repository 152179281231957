@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.CityStatCounter {
	position: relative;
	white-space: nowrap;
	height: 100%;
	overflow: hidden;
	user-select: none;
	> span {
		display: inline-block;
		position: relative;
		height: 100%;
	}

	.orig-val {
		visibility: hidden;
		display: block;
		min-height: 100%;
		&.anim-complete {
			visibility: visible;
		}
	}

	.val-col {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		opacity: 0;
		> div {
			display: block;
			height: 100%;
		}
	}
}

@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.NationalGuidesCTA {
	bottom: $margin-vertical;
	display: flex;
	align-items: center;
	justify-items: center;
	cursor: pointer;
	@include mediaQuery(lrg) {
		@include center-x;
	}
	.label {
		font-family: $font-family-martin;
		font-size: 70px;
		line-height: 50px;
		color: white;
		text-transform: uppercase;
		margin-bottom: 30px;
		@include mediaQuery(lrg) {
			font-family: $font-family-william;
			font-size: 14px;
			line-height: 21px;
			text-transform: uppercase;
			padding: 0 36px;
			transition: $color-transition;
			margin-bottom: unset;
		}
	}

	.arrows {
		width: 21px;
		height: 16px;
		display: none;
		@include mediaQuery(lrg) {
			display: block;
		}
	}

	svg {
		path {
			transition: fill 0.2s ease-out;
		}
	}

	&.dark {
		.label {
			color: var(--nav-color-bc);
		}

		svg {
			path {
				fill: var(--nav-color-bc);
			}
		}
	}

	&.light {
		.label {
			color: $text-color;
		}

		svg {
			path {
				fill: $text-green;
			}
		}
	}
}

@include hover {
	.NationalGuidesCTA:hover {
		&.dark {
			.label {
				color: var(--nav-color-bc-hover);
			}

			svg {
				path {
					fill: var(--nav-color-bc-hover);
				}
			}
		}

		&.light {
			.label {
				color: $color-gold;
			}

			svg {
				path {
					fill: $color-gold;
				}
			}
		}
	}
}

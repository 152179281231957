@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.ToolTip {
	position: relative;
	white-space: nowrap;
	font-family: $font-family-william;
	font-size: 11px;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 0.03em;
	padding: 9px 12px 6px;
	display: block;
	position: absolute;
	bottom: calc(100% + 10px);
	right: -6px;
	background-color: $text-color;
	color: $text-color-dark;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.2s ease-out;

	> span {
		display: block;
		position: relative;
	}

	&::before {
		content: '';
		position: absolute;
		right: 6px;
		transform: rotate(45deg);
		bottom: -4px;
		width: 15px;
		height: 15px;
		background-color: $text-color;
	}

	&.arrow-left-side {
		right: unset;
		left: -6px;
		&::before {
			right: unset;
			left: 10px;
		}
	}
}

@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.AboutSection {
  position: relative;
  display: block;
  width: 100%;
  margin: 20px 0;
  padding: 20px 0 0;
  
  .about-top-sep {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-top: 1px solid $color-gold;
  }
}
@import '../../../../../scss/constants.scss';
@import '../../../../../scss/mixins.scss';

.AboutFonts {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 25px 0 35px;

  @include mediaQuery(mobile) {
    flex-direction: column;
  }

  .font-block {
    flex: 1;
    overflow: hidden;
    &:first-of-type {
      margin-right: 35px;
      @include mediaQuery(mobile) {
        margin-right: 0;
      }
    }
  }

  .font-image {
    position: relative;
    height: 250px;
    width: 100%;
    overflow: hidden;
    .image-motion-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      > img {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    
  }

  .image-credit {
    font-family: $font-family-william;
    font-size: 10px;
    line-height: 10px;
    color: #A1A0A2;
    margin: 2px 0 20px;
  }

  .font-name {
    text-transform: uppercase;
    margin: 10px 0 20px;
    font-weight: normal;
    
    &.font-martin {      
      font-family: $font-family-martin;
      font-size: 40px;
      line-height: 43px;
      letter-spacing: normal;
    }
    &.font-william {
      font-family: $font-family-william;
      font-size: 32px;
      line-height: 32px;
      padding-top: 10px;
    }     
  } 

}
@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

$padding: 74px;

.Search {
	@include fill;
	pointer-events: none;
	z-index: $layer-content;

	@include mediaQuery(sml) {
		background-color: $color-almost-black;
		pointer-events: auto;
		overflow: hidden auto;
		z-index: $layer-ui-1;
	}

	@include mediaQuery(lrg) {
		z-index: $layer-ui-4;
	}

	.backing {
		@include fill;
		background-color: transparent;
	}

	.content {
		@include center-x;
		width: 520px;
		max-width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		@include mediaQuery(sml) {
			margin-top: 120px;
			padding: 0px $margin-horizontal-mobile;
		}

		@include mediaQuery(lrg) {
			top: 20px;
			padding: 20px 40px;
			background: rgba(32, 31, 33, 0.9);
		}

		.header-container {
			align-self: flex-start;
			width: 100%;

			.header {
				font-family: $font-family-martin;
				font-size: 50px;
				line-height: 34px;
				color: $color-gold;
				text-transform: uppercase;
			}

			.searchbar {
				position: relative;
				z-index: 1;
			}
		}

		.content-container {
			width: 100%;
			height: 70%;
			overflow-y: auto;
			align-self: flex-start;

			.title {
				font-family: $font-family-martin;
				font-size: 50px;
				line-height: 34px;
				color: $color-gold;
				text-transform: uppercase;
				margin-bottom: 43px;
			}

			.content-sections {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.item-section {
					display: grid;
					grid-template-columns: auto auto auto;
					width: 75%;

					.item {
						cursor: pointer;

						.image {
							width: 154px;
							height: 86px;
							background-color: #151515;
							margin: 0 35px 5px 0;
						}

						.label {
							font-family: $font-family-william;
							font-size: 16px;
							line-height: 20px;
							letter-spacing: 0.03em;
							text-transform: uppercase;
							color: $text-color;
							margin-bottom: 40px;
							transition: $color-transition;
						}
					}

					@include hover {
						.item:hover {
							.label {
								color: $color-gold;
							}
						}
					}
				}
			}
		}
	}

	.footer-container {
		margin-top: auto;
	}
}

.suggestions-wrapper {
	overflow-y: auto;
}

.Search > * {
	pointer-events: auto;
}

@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.AboutNav {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  right: 0;
  top: 0;
  max-width: 600px;
  justify-content: flex-end;
  pointer-events: auto;
  
  > a {
    position: relative;
    display: inline-block;
    font-family: $font-family-william;
    color: $text-color;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
    padding: 0 10px;
    transition: $color-transition;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-right: 1px solid $color-gold;
      height: 100%;
    }
    &:nth-child(3n) {
      padding: 0 0 0 10px;
      &::after {
        display: none;
      }
    }
  }

  @include hover {	
    > a:hover {
      color: $color-gold;
    }
	}

  @include mediaQuery(mobile) {
    flex-direction: column;
    top: -40px;
    right: unset;
    left: 0;
    max-width: 400px;

    > a {
      padding: 0 0 0 10px;
      display: block;
      border-left: 1px solid $color-gold;
      &::after {
        display: none;
      }
    }
  }
  
}
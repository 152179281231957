@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

$padding: 55px;

.NationalGuides {
	@include fill;
	pointer-events: none;
	z-index: $layer-ui-4;
	overflow: auto;

	@include mediaQuery(sml) {
		@include fill;
		background: #0e0c0f;
		z-index: $layer-content;
		overflow: hidden;
	}

	.content {
		position: relative;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		@include mediaQuery(sml) {
			position: absolute;
			top: 120px;
			height: calc(100% - 200px);
			width: 100%;
			margin: 0;
			padding: 0 20px;
			overflow: hidden;
		}

		@include mediaQuery(lrg) {
			@include center-x;
			bottom: 0;
			width: 886px;
			max-width: 100%;
			height: 738px;
			max-height: 90vh;
			background: rgba(32, 31, 33, 0.9);
			padding: 22px $padding 0 $padding;
		}

		@include mediaQuery(tabletPortrait) {
			height: calc(100% - 25px);
		}

		.NationalGuidesCTA.light .label {
			@include mediaQuery(sml) {
				margin-bottom: 10px;
			}
		}

		.sub-header {
			font-family: $font-family-william;
			font-size: 16px;
			line-height: 20px;
			color: $text-color;
			letter-spacing: 0.03em;
			text-align: left;
			margin-top: 0;

			@include mediaQuery(sml) {
				max-width: 90%;
			}

			@include mediaQuery(lrg) {
				margin-top: 22px;
				text-align: center;
			}
		}

		.content-container {
			flex: 1;
			overflow-y: auto;
			align-self: flex-start;
			display: flex;
			width: 100%;
			padding-top: 15px;

			@include mediaQuery(lrg) {
				padding-top: unset;
				flex: 1;
				height: unset;
				margin-top: 15px;
			}

			.content-sections {
				flex: 1;
				display: flex;
				width: 100%;
				overflow-y: scroll;

				.item-section {
					width: 100%;
					padding-top: 15px;

					.bottom-spacer {
						height: 1px;
					}

					.item {
						cursor: pointer;
						display: flex;
						flex-direction: row;
						margin-bottom: 30px;
						padding: 0 10px 0 0;
						flex-grow: 0;
						align-items: flex-start;

						@include mediaQuery(lrg) {
							padding: 0 10px 0 10px;
						}

						.image {
							width: 88px;
							height: 52px;
							background-color: #151515;
							margin: 0 20px 0 0;
							flex-shrink: 0;

							@include mediaQuery(lrg) {
								width: 154px;
								height: 86px;
							}
						}

						.text {
							display: flex;
							flex-direction: column;
							flex-grow: 1;
							padding-top: 5px;

							@include mediaQuery(lrg) {
								padding-top: 2px;
							}

							.text-title-container {
								display: flex;
								justify-content: space-between;

								.text-title {
									font-family: $font-family-martin;
									font-size: 42px;
									line-height: 28px;
									color: $text-color;
									text-transform: uppercase;
									margin: -8px 0 12px 0;
									transition: $color-transition;

									@include mediaQuery(lrg) {
										margin: -8px 0 22px 0;
										font-size: 80px;
										line-height: 50px;
									}
								}
							}

							.text-body {
								font-family: $font-family-william;
								font-size: 12px;
								line-height: 16px;
								letter-spacing: 0.03em;
								color: $text-color;
								transition: $color-transition;

								@include mediaQuery(lrg) {
									font-size: 14px;
									line-height: 20px;
								}
							}

							svg {
								transition: opacity 0.2s ease-out;
								opacity: 0;

								path {
									fill: $color-gold;
								}
							}
						}
					}

					@include hover {
						.item:hover {
							.text {
								.text-title {
									color: $color-gold;
								}

								.text-body {
									color: $color-gold;
								}

								svg {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}

		.footer-container {
			margin-top: auto;
		}
	}
}

.NationalGuides > * {
	pointer-events: auto;
}

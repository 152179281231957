@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.PoiCard {
	display: flex;
	flex: 1;
	background-color: $color-gold;
	justify-content: flex-start;
	padding: 12px;
	cursor: pointer;
	transition: $background-color-transition;

	margin-bottom: 15px;
	&:last-of-type {
		margin-bottom: 0;
	}

	.poi-card-image {
		width: 124px;
		height: 75px;
		margin-right: 23px;
		background-color: rgba(255, 255, 255, 0.2);
		transition: $background-color-transition;

		@include mediaQuery(sml) {
			display: none;
		}

		@include mediaQuery(lrg) {
			width: 185px;
			height: 218px;
			flex-shrink: 0;
		}
	}
	&.curated,
	&.favorites {
		background-color: $color-curated;
		&:hover {
			background-color: lighten($color-curated, 15%);
		}
	}

	&.entertainment {
		background-color: $color-entertainment;
		&:hover {
			background-color: lighten($color-entertainment, 15%);
		}
	}

	&.see {
		background-color: $color-see;
		&:hover {
			background-color: lighten($color-see, 15%);
		}
	}

	&.beauty,
	&.self-care {
		background-color: $color-beauty;
		&:hover {
			background-color: darken($color-beauty, 15%);
		}
	}

	&.eat {
		background-color: $color-eat;
		&:hover {
			background-color: lighten($color-eat, 15%);
		}
	}

	&.flex {
		background-color: $color-flex;
		&:hover {
			background-color: darken($color-flex, 15%);
		}
	}

	&.learn,
	&.know {
		background-color: $color-learn;
		&:hover {
			background-color: lighten($color-learn, 15%);
		}
	}

	&.shop {
		background-color: $color-shop;
		&:hover {
			background-color: darken($color-shop, 15%);
		}
	}

	&.stay {
		background-color: $color-stay;
		&:hover {
			background-color: lighten($color-stay, 15%);
		}
	}

	&.general {
		background-color: $color-general;
		&:hover {
			background-color: lighten($color-general, 15%);
		}
	}
	.poi-card-content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;

		@include mediaQuery(lrg) {
			min-height: 218px;
		}

		.poi-card-content-location-header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			margin-bottom: 20px;

			.poi-card-title {
				font-family: $font-family-martin;
				font-size: 30px;
				line-height: 20px;
				color: $text-color;
				text-transform: uppercase;
				overflow: hidden;
				max-width: 28ch;
				max-height: 64px;
				padding-bottom: 4px;

				@include mediaQuery(lrg) {
					// font-size: 16px;
					line-height: 20px;
				}
			}

			.poi-card-icon {
				position: relative;
				width: 30px;
				height: 30px;

				.poi-icon-bg {
					width: 22px;
					height: 22px;
					background-color: $color-almost-black;
					transform-origin: center;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
					transition: $background-color-transition;
				}

				img {
					@include center;
					height: 28px;
				}
			}
		}

		.poi-card-content-location-details {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			font-family: $font-family-william;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.03em;
			color: $text-color-dark;

			.poi-rating {
				font-weight: bold;
				margin-right: 10px;
			}

			.StarRating {
				margin-top: -4px;
				margin-right: 8px;
			}

			.poi-review-price-and-type {
				display: flex;
				flex-basis: 100%; //wrap here
				margin-top: 0;
			}

			.poi-address {
				margin-top: 13px;
				max-width: 80%;
				white-space: pre-line;
				&--historical {
					margin-top: 0;
				}
			}
		}

		.poi-hitarea {
			@include fill;
		}

		.poi-card-content-footer {
			margin: auto 0 0 0;

			.poi-divider {
				width: 100%;
				height: 1px;
				background-color: $text-color-dark;
				margin-bottom: 8px;
			}

			.poi-no-flag {
				position: relative;
				width: 1px;
				height: 12px;
				pointer-events: none;
			}

			.poi-flag--wrap {
				position: relative;
				width: 10px;
				height: 12px;
				margin: 0 0 10px auto;
			}
			.poi-flag {
				position: relative;
				overflow: visible;
				width: 10px;
				height: 12px;
				background-color: $text-color-dark;
				-webkit-mask: url(../../../images/icons/flag.svg) no-repeat center;
				mask: url(../../../images/icons/flag.svg) no-repeat center;

				transition: $background-color-transition;
				cursor: pointer;

				&.active {
					background-color: $text-color;
				}
			}

			.tooltip {
				opacity: 0;
			}

			@include hover {
				.poi-flag--wrap:hover {
					.poi-flag {
						background-color: $text-color;
					}
					.tooltip {
						opacity: 1;
					}
				}
			}
		}
	}
}

@include hover {
	.PoiCard:hover {
		background-color: lighten($color-gold, 15%);

		.poi-card-image {
			// background-color: $color-gold;
		}
	}
}

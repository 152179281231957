@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.SocialEmbed {
	opacity: 0;
	transition: opacity 1s ease-in-out;

	&.visible {
		opacity: 1;
	}
}

.crt-logo {
	color: $text-color !important;
	transition: $color-transition;
}

@include hover {
	.crt-logo:hover {
		color: $color-gold !important;
	}
}

@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

.Privacy {
	.Privacy-copy {
		width: 100%;
		height: 100%;
		align-self: flex-start;

		h3 {
			text-transform: uppercase;
		}

		p {
			width: 800px;
			max-width: 100%;
		}

		a {
			transition: $color-transition;
		}

		@include hover {
			a:hover {
				color: $color-gold;
			}
		}
	}
}

@font-face {
  font-family: 'VTCWilliam';
  src: local('VTCWilliam'), url(fonts/VTC_William_Desktop/VTCWilliam-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'VTCWilliam';
  src: local('VTCWilliam-Bold'), url(fonts/VTC_William_Desktop/VTCWilliam-Bold.ttf) format('truetype');
  font-weight: 'bold';
}

/* add more here as needed */

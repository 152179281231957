@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.CityStats {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 38px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	max-width: 100%;

	&.hidden {
		.city-stat-desc {
			pointer-events: none;
			opacity: 0;
		}
	}

	&.bottom {
		padding-top: 18px;
	}

	.city-stat-desc {
		display: flex;
		position: relative;
		width: calc(100% - 40px - #{$margin-horizontal-mobile} * 2);
		// width: 540px;
		margin-bottom: 30px;
		align-items: center;
		transition: opacity 0.2s ease-out, border-color 0.2s ease-out, $background-color-transition;
		background-color: $color-almost-black;
		padding: 15px 20px;
		height: 40px;
		border: 1px solid $color-gold;

		@include mediaQuery(lrg) {
			width: 540px;
		}

		@include mediaQuery(tablet) {
			width: 540px;
		}

		// only in the DOM when description is closed to maintain layout
		&.hidden {
			background-color: transparent;
			border-color: transparent;
		}

		&.bottom {
			margin-top: 40px;
			width: unset;
			margin-bottom: -10px;
			background-color: transparent;
			padding: 0;
			border: none;

			.city-stat-desc-text {
				height: unset;
				margin-right: 0;
			}
		}

		.city-stat-desc-text {
			font-family: $font-family-william;
			font-size: 14px;
			line-height: 20px;
			color: $text-color;
			margin-right: 40px;

			@include mediaQuery(lrg) {
				white-space: pre-line;
			}

			&.using-hover {
				margin-right: 0;
				text-align: center;
				width: 100%;
			}
		}
	}

	.city-stats-container {
		display: flex;
		width: 100%;
		padding: 0 30px;
		align-items: flex-end;
		justify-content: space-between;

		@include mediaQuery(tablet) {
			width: 540px;
		}

		.city-stats-item {
			position: relative;
			margin-bottom: -7px;
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;
			width: 130px;
			flex: 1;

			.bottom-arrow {
				position: absolute;
				left: 50%;
				transform: translateX(-50%) rotate(45deg);
				top: -41px;
				width: 20px;
				height: 20px;
				background-color: $color-almost-black;
				border-right: 1px solid $color-gold;
				border-bottom: 1px solid $color-gold;
				transition: opacity 0.2s ease-out;
			}

			.stat-value {
				font-family: $font-family-william;
				font-size: 16px;
				line-height: 16px;
				height: 20px;
				color: $text-color;
				text-align: center;
				transition: $color-transition;
				position: relative;
				@include mediaQuery(lrg) {
					font-size: 26px;
					line-height: 30px;
					height: 30px;
				}
			}

			.stat-label {
				font-family: $font-family-william;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.03em;
				color: $text-color;
				text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				text-align: center;
				margin-bottom: 10px;
				transition: $color-transition;
				white-space: pre-line;
				height: 48px;

				@include mediaQuery(lrg) {
					font-size: 14px;
					line-height: 14px;
					height: 42px;
				}
			}

			.stat-icon {
				width: 8px;
				height: 8px;
				transform: rotate(45deg);
				border: 1px solid $text-color;
				transition: $background-color-transition, border-color 0.2s ease-out;

				@include mediaQuery(lrg) {
					width: 13px;
					height: 13px;
				}
			}

			&.active {
				pointer-events: none;
			}

			&.active,
			&.hovered {
				.stat-value {
					color: $text-color;
				}

				.stat-label {
					color: $text-color;
				}

				.stat-icon {
					background-color: $text-color;
				}
			}
		}

		@include hover {
			.city-stats-item:hover {
				.stat-value {
					color: $color-gold;
				}

				.stat-label {
					color: $color-gold;
				}

				.stat-icon {
					border-color: $color-gold;
				}

				&.active,
				&.hovered {
					.stat-icon {
						background-color: $color-gold;
					}
				}
			}
		}
	}
}

@import '../../../scss/mixins.scss';

.Terms{
	.Terms-copy{

		width: 100%;
		height: 100%;
		overflow-y: auto;
		align-self: flex-start;
		
		p{
			width: 800px;
			max-width: 100%;
			font-size: 12px;
			line-height: 16px;
			@include mediaQuery(lrg) {
				font-size: unset;
			}
		}
		
	}
}
@import '../../../scss/mixins.scss';
@import '../../../scss/constants.scss';

$padding-h: 75px;
$padding-v: 75px;

.CityGuides {
	.title {
		margin-bottom: 10px;

		@include mediaQuery(sml) {
			font-size: 70px;
			line-height: 50px;
		}
	}

	.subtitle {
		font-family: $font-family-william;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.03em;
		color: $text-color;
		margin-bottom: 30px;
	}

	.content-container {
		width: 100%;
		align-self: flex-start;

		.content-sections {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.item-section {
				width: 100%;

				@include mediaQuery(lrg) {
					display: grid;
					gap: 20px;
					width: 100%;
					margin-bottom: 30px;
				}

				.item {
					cursor: pointer;
					overflow: hidden;
					@include mediaQuery(sml) {
						display: flex;
						margin-bottom: 30px;
					}
					.image-container {
						position: relative;
						overflow: hidden;
						width: 100%;
						max-width: 80px;
						margin-right: 20px;
						@include mediaQuery(lrg) {
							max-width: unset;
							margin-right: 0;
						}
					}
					.image-wrap {
						position: relative;
						width: 100%;
						overflow: hidden;

						&::before {
							content: '';
							display: block;
							padding-top: 67%;
							width: 100%;
						}
					}
					.image {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-size: cover;
						background-position: center;
						background-color: #151515;
						transition: 0.2s transform ease-out;
					}

					.label {
						font-family: $font-family-martin;
						font-size: 42px;
						line-height: 28px;
						color: $text-color;
						text-transform: uppercase;
						margin: 8px 0 12px 0;
						transition: $color-transition;

						@include mediaQuery(lrg) {
							font-family: $font-family-william;
							margin: unset;
							margin-bottom: 0;
							margin-top: 10px;
							font-size: 16px;
							line-height: 20px;
							letter-spacing: 0.03em;
						}
					}
				}

				@include hover {
					.item:hover {
						.label {
							color: $color-gold;
						}
						.image {
							transform: scale(1.1);
						}
					}
				}
			}
		}
	}
}

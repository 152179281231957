@import '../../../scss/constants.scss';

.StarRating {
	position: relative;
	width: 70px;
	height: 14px;
	margin-right: 8px;

	.bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 70px;
		height: 14px;
		background-color: darken($color-gold, 15%);
		-webkit-mask: url(../../../images/icons/star.svg) repeat-x left;
		mask: url(../../../images/icons/star.svg) repeat-x left;
	}

	.fg {
		position: absolute;
		left: 0;
		top: 0;
		width: 70px;
		height: 14px;
		background-color: $color-gold;
		-webkit-mask: url(../../../images/icons/star.svg) repeat-x left;
		mask: url(../../../images/icons/star.svg) repeat-x left;
	}
}

@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

$padding: 74px;
$paddingmobile: 30px;

.About {
	@include fill;
	pointer-events: none;
	z-index: $layer-ui-4;

	h1, h2 {
		font-family: $font-family-martin;
		font-size: 50px;
		line-height: 34px;
		color: $color-gold;
		text-transform: uppercase;
		margin: 0 0 35px;
		font-weight: normal;
	}

	h3 {
		font-weight: normal;
	}
	
	p {
		margin-bottom: 1rem;
		max-width: 720px;
		a {
			color: $text-color;
		}
	}

	.content {
		width: 100%;
		height: 100%;
		background-color: $background-color;
		padding: $padding $padding 0 $padding;
		box-sizing: border-box;
		overflow-y: auto;
		scroll-behavior: smooth;
		color: $text-color;

		@include mediaQuery(mobile) {
			padding: $padding $paddingmobile 0;
		}
		
		.header-container {
			position: relative;
			width: 100%;
		}

		.content-container {
			display: flex;
			width: 100%;
			max-width: 1024px;
			margin: 0 auto;
			flex-direction: column;	
		}

		.footer-container {
			margin-top: auto;
			padding-top: 50px;
		}
	}
}

.About > * {
	pointer-events: auto;
}

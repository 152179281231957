@import '../../../scss/constants.scss';
@import '../../../scss/mixins.scss';

.RotateDevice {
	@include fill-view;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: $layer-rotate-message;
	background-color: $color-almost-black;

	@include mediaQuery(mobilePortrait) {
		display: none;
	}

	.device {
		width: 64px;
		height: 106px;
		background-color: $color-gold;
		-webkit-mask: url(../../../images/ui/rotate-device.svg) no-repeat center;
		mask: url(../../../images/ui/rotate-device.svg) no-repeat center;
		margin-bottom: 20px;
	}

	.message {
		font-family: $font-family-martin;
		font-size: 42px;
		line-height: 28px;
		text-align: center;
		text-transform: uppercase;
		color: $text-color;
	}
}

@import '../../../../../scss/constants.scss';
@import '../../../../../scss/mixins.scss';

.AboutPartners {
  display: block;
  margin: 25px 0 35px;

  .partner-item {
    display: block;
    position: relative;
    margin-bottom: 4px;
    > a {
      display: block;
      width: 100%;
      color: $text-color;
      transition: 0.2s transform ease-out;
    }
  }

  @include hover {
    .partner-item {
      > a:hover {
        transform: scale(0.95);
      }
    }
  }
}